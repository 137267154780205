import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './EditCostCentreUsers.styles';
import { useForm } from "react-hook-form";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import Typography from "@material-ui/core/Typography/Typography";

import { closeEditCostCentreUsersModal } from '../../../actions/uiActions';

import { LoadingSpinner }  from "../../LoadingSpinner";

import { 
  getCostCentreUsers,
  updateCostCentrePermissions,
} from '../../../actions/costCentreActions';

import { 
  getUsers,
} from '../../../actions/customerActions';

import Autocomplete from '@material-ui/lab/Autocomplete';

function EditCostCentreUsersForm(props) {

  const { 
    classes, 
    costCentres,
    customers,
    modalText,
    ui,
  } = props;

  const { activeCostCentre } = costCentres;

  let defaultValues = {
    Name: costCentres.activeCostCentre.Name,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, errors } = methods;

  const [customerUsers, setCustomerUsers] = useState([]);
  const [costCentreUsers, setCostCentreUsers] = useState([]);
  const [autocompleteState, setAutocompleteState] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);
  const [initialUsersIds, setInitialUsersIds] = useState([]);
  const [modifiedUsersIndexList, setModifiedUsersIndexList] = useState([]);

  const onSubmit = formData => {

    let addedUsers = costCentreUsers.filter((user) => !initialUsersIds.includes(user.id));
    let updatedUsers = costCentreUsers.filter((user) => modifiedUsersIndexList.includes(user.id));

    let costCentreUserIds = costCentreUsers.map(o => o.id);
    let removedUsers = initialUsers.filter((user) => !costCentreUserIds.includes(user.id));
    let removedUserIds = removedUsers.map(o => o.id);

    let formDataWithUsers = {
      CostCentreKey: activeCostCentre.CostCentreKey,
      updatedUsers: Array.from(new Set(updatedUsers.concat(addedUsers).filter((user) => !removedUserIds.includes(user.id)))),
      removedUsers: removedUsers,
      userState: costCentreUsers
    }

    props.updateCostCentrePermissions(formDataWithUsers);
  }

  useEffect(()  => {
    props.getCostCentreUsers(activeCostCentre.CostCentreKey);
    props.getUsers();
  }, []);

  useEffect(() => {
    if (activeCostCentre.isUsersLoaded) {
      setCostCentreUsers(activeCostCentre.users);

      setInitialUsers(activeCostCentre.users);
      setInitialUsersIds(activeCostCentre.users.map(o => o.id));

      setAutocompleteState(activeCostCentre.users.filter(user => user.RoleName == 'Customer'));
    }
  }, [activeCostCentre]);

  useEffect(() => {
    if (customers.isUsersLoaded) {
      setCustomerUsers(customers.users.filter(user => user.CustomerSK === activeCostCentre.CustomerSK));
    }
  }, [customers]);

  const getStripedStyle = (index) => {
    return { background: index % 2 ? '#F3F5F5' : 'none' };
  }

  const handleTogglePermission = (event, index) => {
    event.preventDefault();

    setModifiedUsersIndexList([
      ...modifiedUsersIndexList,
      costCentreUsers[index].id,
    ]);

    switch(costCentreUsers[index].PermissionLevel){
      case 'Admin':
        costCentreUsers[index].PermissionLevel = 'Reader';
        break;
      case 'Reader':
        costCentreUsers[index].PermissionLevel = 'Admin';
        break;
    }

    setCostCentreUsers([...costCentreUsers]);
  }

  const handleRemoveUser = (event, index) => {
    let costCentreUsersFiltered = costCentreUsers.filter(user => user.id !== costCentreUsers[index].id);
    setCostCentreUsers(costCentreUsersFiltered);

    setAutocompleteState(costCentreUsersFiltered);
  }

  const ColorButton = withStyles((theme, ...props) => ({
    root: {
      color: props => props.cool,
      borderColor: props => props.cool,
      '&:hover': {
        backgroundColor: props => props.cool,
        color: '#ffffff',
        borderColor: props => props.cool,
      },
    },
  }))(Button);

  const filterSelectedUsers = () => {
    const userIds = costCentreUsers.map(o => o.id);
    const filteredUser = customerUsers.filter((user) => !userIds.includes(user.id));
    
    return filteredUser.filter(user => user.RoleName == 'Customer');
  }

  const handleChangeUserPermissions = inputState => {

    setAutocompleteState(inputState);

    const userIds = inputState.map(o => o.id);
    const removedUser = autocompleteState.find(user => !userIds.includes(user.id));

    if (removedUser) {
      let costCentreUsersFiltered = costCentreUsers.filter(user => user.id !== removedUser.id);
      setCostCentreUsers(costCentreUsersFiltered);
    } else {
      let userToAdd = inputState[inputState.length - 1];
      
      let userWithPermission = {
        ...userToAdd,
        PermissionLevel: "Reader"
      }

      setCostCentreUsers([...costCentreUsers, userWithPermission]);
    }
  }

  return (
    <Fragment>
      
      <Dialog
        open={ui.editCostCentreUsersModalOpen}
        onClose={props.closeEditCostCentreUsersModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <Typography variant="h2">{modalText}</Typography>
              </Grid>
          
              {customerUsers && activeCostCentre.isUsersLoaded &&

                <Grid container item xs={12}>
                  <Autocomplete
                  classes={{
                    root: classes.muiAutoCompleteRoot
                  }}
                  disableClearable
                  multiple
                  options={filterSelectedUsers()}
                  value={costCentreUsers.filter(user => {
                    console.log(user);
                    return  user.RoleName == 'Customer'
                  })}
                  getOptionLabel={option => `${option.firstname} ${option.lastname}`}
                  filterSelectedOptions
                  onChange={(event, newValue) => {
                    handleChangeUserPermissions(newValue)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Available Users"
                      placeholder="Add User to Cost Centre"
                      fullWidth
                    />
                  )}
                  />
                </Grid>
              }
                  
              {activeCostCentre.isUsersLoaded &&

                <Grid container item xs={12}>
                  <Typography variant="h4">User List</Typography>
                  
                  <ul style={{width: '100%'}}>
                    {costCentreUsers.map((user, index) => (
                      user.RoleName === 'Customer' ?
                      <li key={user.id} style={{ padding: '10px 20px',
                        display: 'flex',
                        alignItems: 'center', 
                        ...getStripedStyle(index) }}>
                        <Typography style={{flexGrow: 1}}>{user.firstname} {user.lastname} ({user.email})</Typography>

                          <ColorButton
                            style={{marginRight: '15px'}}
                            color="primary"
                            variant="outlined"
                            onClick={event => handleTogglePermission(event, index)}
                            cool={user.PermissionLevel == 'Admin' ? '#00B48D' : '#9B52B6'}
                          >
                            {user.PermissionLevel}
                          </ColorButton>
                          <Button 
                            color="primary"
                            variant="outlined"
                            onClick={event => handleRemoveUser(event, index)}
                          >
                            Remove
                          </Button>
                      </li> : ''
                    ))}
                  </ul>

                </Grid>
              }

              {!activeCostCentre.isUsersLoaded &&
                <LoadingSpinner/>
              }

              <Grid container item xs={12} direction="row"  justify="flex-end" className={classes.dialogButtons}>
                <Button 
                  onClick={props.closeEditCostCentreUsersModal} 
                  color="primary"
                  variant="outlined"
                  className={classes.cancelButton}
                  disabled={costCentres.updatingCostCentres}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={costCentres.updatingCostCentres}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  )
}

const mapDispatchToProps = {
  getUsers,
  getCostCentreUsers,
  closeEditCostCentreUsersModal,
  updateCostCentrePermissions,
};

const mapStateToProps = (state) => ({
  costCentres: state.costCentres,
  customers: state.customers,
  ui: state.ui,
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditCostCentreUsersForm);
