import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, BarChart, Bar, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { styles } from './SubscriptionsDailyBarChart.styles';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { getReportChartSubscription } from "../../../utility/api";
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { LoadingSpinner }  from "../../LoadingSpinner";

const SubscriptionsDailyBarChart = (props) => {

  const [chartData, setChartData] = useState(false);
  const [currencyType, setCurrenyType] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);
  const [subscription, setSubscription] = useState('');

  const token = props.account.jwt;

  let { classes, costCentres, selectedCustomer, account } = props;

  useEffect(() => {
    if (selectedCustomer == '') {
      selectedCustomer = account.CustomerSK;
    }
  }, []);

  useEffect(()  => {

    if (selectedCustomer == '') {
      selectedCustomer = account.CustomerSK;
    }

    setSubscription('');
    setSubscriptionsLoading(true);

    let filteredCostCentres = costCentres.overview
    .filter(costCentre => costCentre.CustomerSK === selectedCustomer);

    let subs = [];
    let shouldUpdateState = true;

    for (let i = 0; i < filteredCostCentres.length; i++) {
      if (filteredCostCentres[i].isDetailLoaded) {
        for (let j = 0; j < filteredCostCentres[i].subscriptions.length; j++) {
          if (filteredCostCentres[i].subscriptions[j].SubscriptionPresentationType === 'Azure' || filteredCostCentres[i].subscriptions[j].SubscriptionPresentationType === 'AWS') {
            subs.push(filteredCostCentres[i].subscriptions[j]);
          }
        }
      } else {
        shouldUpdateState = false;
      }
    }

    if (shouldUpdateState) {
      setSubscriptions(subs);
      
      setSubscriptionsLoading(false);
      
      if(subs.length > 0){
        setSubscription(subs[0].SubscriptionSK);
        getChartData(subs[0].SubscriptionSK);
      }
    }

  }, [selectedCustomer, costCentres]);

  const getChartData = (subscription) => {
    getReportChartSubscription(token, subscription, 'daily').then(data => {
      let formattedData = formatData(data);
      setChartData(formattedData);
      setCurrenyType( data[0] ? data[0].CurrencyName : 'Unknown');
    });
  }

  const formatData = (chartData) => {
    let result = chartData.map(data => {
      data.UsageDate = moment(data.UsageDate).format('D/MM');
      return data;
    });

    return result;
  }

  const formatToolTip = (value, label) => {
    return [value, getLabel(label)];
  }

  const getLabel = (originalLabel) => {
    switch(originalLabel) {
      case 'SupportCost':
        return 'Managed Service Charge';
      case 'UsageCost':
        return 'Cloud Usage';
      default:
        return originalLabel;
    }
  }

  const handleChangeSubscription = event => {
    setSubscription(event.target.value);
    getChartData(event.target.value);
  };

  const renderLegend = (props) => {
    const { payload } = props;
      
    if (subscriptionsLoading && selectedCustomer){
      return (
        <LoadingSpinner/>
      )
    }
  
    if (!subscriptionsLoading && selectedCustomer){
      return (
        <Grid container spacing={3}>
          <Grid container item xs={6}>
            <ul>
              {
                payload.map((entry, index) => (
                  <li key={`item-${index}`} className={classes.legendItem}>

                    <svg width="20" height="22" viewBox="0 0 32 32" version="1.1" className={classes.legendIconWrapper}>
                      <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className={classes.legendIcon}></path>
                    </svg>

                    <span className={classes.legendLabel}>{getLabel(entry.value)}</span>
                    
                  </li>
                ))
              }
            </ul>
          </Grid>
          <Grid container item xs={6} className={classes.alignRight}>
            <InputLabel id="sort" className={classes.labelInline}>Subscription/Account</InputLabel>
            <Select
              variant="outlined"
              onChange={handleChangeSubscription}
              value={subscription}
              labelId="sort"
              id='Select'
              className={classes.select}
              
            >
            {subscriptions.map(subscription => (
              <MenuItem value={subscription.SubscriptionSK} key={subscription.SubscriptionSK}>
                {subscription.Description}
              </MenuItem>
            ))}
            </Select>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <ResponsiveContainer height={500}>
      <BarChart data={chartData} margin={{top: 25, right: 25, bottom: 0, left: 25}}>

        <defs>
          <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1EA2E8" />
              <stop offset="95%" stopColor="#5290F9"/>
          </linearGradient>
          <linearGradient id="orangeGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#EB8B25"/>
              <stop offset="95%" stopColor="#FA5400"/>
          </linearGradient>
        </defs>

        {chartData && subscription &&
          <XAxis dataKey="UsageDate" />
        }
        {chartData && subscription &&
          <YAxis width={100} label={{ value: currencyType,position: 'insideLeft' }} />
        }
        <Legend content={renderLegend} verticalAlign="top" align="left" height={150}/>

        <Tooltip formatter={ formatToolTip }/>

        {subscription &&
          <Bar stackId="a" dataKey="SupportCost" fill='url(#orangeGradient)'/>
        }
        {subscription &&
          <Bar stackId="a" dataKey="UsageCost" fill="url(#blueGradient)" />
        }
      </BarChart>
    </ResponsiveContainer>
  );
}

const mapStateToProps = (state) => ({
  account: state.account,
  customers: state.customers,
  costCentres: state.costCentres,
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
  )
)(SubscriptionsDailyBarChart);