import {
    GET_EXPENDITURE,
    GET_EXPENDITURE_FAILURE,
    GET_EXPENDITURE_SUCCESS
  } from '../actions/expenditureActionTypes';
  
export const expenditure = (state = { current_month: [], previous_month: [], credits: [] }, action) => {

    switch (action.type) {
        case GET_EXPENDITURE_SUCCESS:
            return action.payload.data || state;
        default:
            return state;
    }
};