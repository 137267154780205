export const styles = ({ palette, spacingDefaults, typography }) => ({
  dialogButtons: {
    marginTop: 20,
    marginBottom: 20,
  },
  cancelButton: {
    marginRight: spacingDefaults.double
  },
  muiAutoCompleteRoot: {
    width: '100%',
  },
  hide: {
    display: 'none',
  }
});
