export const styles = ({ mixins, palette, spacingDefaults, MuiDrawer, overrides }) => ({
  loginText: {
    margin: 0,
    fontWeight: 500,
    textDecoration: 'none',
    fontSize: 12,
  },
  accountIcon: {
    width: 42,
    height: 42,
    fill: '#D8D8D8',
    borderColor: '#979797',
    border: '1px solid',
    borderRadius: '50%',
    marginRight: spacingDefaults.triple,
  },
  arrowDown: {
    marginLeft: spacingDefaults.triple,
    height: 24,
    width: 24,
    color: '#777777',
  },
  menuItem: {
    fontSize: '12px',
    paddingLeft: 20,
    paddingRight: 20,
  },
});
