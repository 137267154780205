import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, Label, LineChart, Line, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import { styles } from './CreditsSpendLineChart.styles';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { getReportChartCredits, getCreditsGraph } from "../../../utility/api";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { Typography } from '@material-ui/core';

const CreditsSpendLineChart = (props) => {

  const [dataLoaded, setDataLoaded] = useState(false);
  const [chartData, setChartData] = useState(null);
  const token = props.account.jwt;
  const [costCentre, setCostCentre] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { classes, costCentres, selectedCustomer } = props;

  useEffect(()  => {
    if (selectedCustomer == '') {
      return;
    }

    getCreditsGraph(token, selectedCustomer).then(res => {
        let formattedData = formatData(res.data);
        setChartData(formattedData);
        setDataLoaded(true);
    }).catch(err => {
      setChartData(null);
      setErrorMessage('No data available');
    });

    console.log(selectedCustomer);

    setCostCentre('');
    setDataLoaded(false);
    setChartData(null);
  }, [selectedCustomer]);
  
  const formatData = (chartData) => {
    let result = chartData.map(data => {
      data.date = moment(data.date).format('D/MM');
      return data;
    });

    return result;
  }

  const formatToolTip = (value, label) => {
    return [value, getLabel(label)];
  }

  const getLabel = (originalLabel) => {
    switch(originalLabel) {
      case 'gbp':
        return 'Pounds';
      case 'usd':
        return 'Dollars';
      default:
        return originalLabel;
    }
  }

  const handleChangeCostCentre = event => {
    setCostCentre(event.target.value);
    // getChartData(event.target.value);
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <Grid container spacing={3}>
        <Grid container item xs={6}>
          <ul>
            {
              payload.map((entry, index) => (
                <li key={`item-${index}`} className={classes.legendItem}>

                  <svg width="20" height="22" viewBox="0 0 32 32" version="1.1" className={classes.legendIconWrapper}>
                    <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className={classes.legendIcon}></path>
                  </svg>

                  <span className={classes.legendLabel}>{getLabel(entry.value)}</span>
                  
                </li>
              ))
            }
          </ul>
        </Grid>   
      </Grid>
    );
  }

  return (
    <ResponsiveContainer height={500}>
      
      <LineChart data={chartData} margin={{
          top: 16,
          right: 16,
          bottom: 0,
          left: 24,
        }}>

        {chartData &&
          <XAxis dataKey="date" />
        }

        {chartData &&
          <YAxis orientation="left" yAxisId="left">
            <Label
              angle={70}
              position="left"
              style={{ textAnchor: 'middle' }}
            >
              £
            </Label>
          </YAxis>
        }

        {chartData &&
          <YAxis orientation="right" yAxisId="right">
            <Label
              angle={70}
              position="left"
              style={{ textAnchor: 'left' }}
            >
              $
            </Label>
          </YAxis>
        }

<Legend content={renderLegend} verticalAlign="top" align="left" height={150}/>
        <Tooltip formatter={ formatToolTip }/>

        {chartData &&
          <Line yAxisId="left" type="monotone" dataKey="gbp" stroke="#64DFA8" dot={false} strokeWidth={3}/>
        }
        {chartData &&
          <Line yAxisId="right" type="monotone" dataKey="usd" stroke="#00A6FF" dot={false} strokeWidth={3}/>
        }
      </LineChart>
    </ResponsiveContainer>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  customers: state.customers,
  costCentres: state.costCentres,
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
  )
)(CreditsSpendLineChart);