export const GET_COST_CENTRES = 'GET_COST_CENTRES';
export const GET_COST_CENTRES_SUCCESS = 'GET_COST_CENTRES_SUCCESS';
export const GET_COST_CENTRES_FAILURE = 'GET_COST_CENTRES_FAILURE';

export const GET_COST_CENTRE_DETAILED = 'GET_COST_CENTRE_DETAILED';
export const GET_COST_CENTRE_DETAILED_SUCCESS = 'GET_COST_CENTRE_DETAILED_SUCCESS';
export const GET_COST_CENTRE_DETAILED_FAILURE = 'GET_COST_CENTRE_DETAILED_FAILURE';

export const GET_COST_CENTRE_USERS = 'GET_COST_CENTRE_USERS';
export const GET_COST_CENTRE_USERS_SUCCESS = 'GET_COST_CENTRE_USERS_SUCCESS';
export const GET_COST_CENTRE_USERS_FAILURE = 'GET_COST_CENTRE_USERS_FAILURE';

export const CREATE_COST_CENTRE = 'CREATE_COST_CENTRE';
export const CREATE_COST_CENTRE_SUCCESS = 'CREATE_COST_CENTRE_SUCCESS';
export const CREATE_COST_CENTRE_FAILURE = 'CREATE_COST_CENTRE_FAILURE';

export const UPDATE_COST_CENTRE = 'UPDATE_COST_CENTRE';
export const UPDATE_COST_CENTRE_SUCCESS = 'UPDATE_COST_CENTRE_SUCCESS';
export const UPDATE_COST_CENTRE_FAILURE = 'UPDATE_COST_CENTRE_FAILURE';

export const DELETE_COST_CENTRE = 'DELETE_COST_CENTRE';
export const DELETE_COST_CENTRE_SUCCESS = 'DELETE_COST_CENTRE_SUCCESS';
export const DELETE_COST_CENTRE_FAILURE = 'DELETE_COST_CENTRE_FAILURE';

export const SET_ACTIVE_COST_CENTRE = 'SET_ACTIVE_COST_CENTRE';

export const MOVE_COST_CENTRE_SUBSCRIPION = 'MOVE_COST_CENTRE_SUBSCRIPION';
export const MOVE_COST_CENTRE_SUBSCRIPION_SUCCESS = 'MOVE_COST_CENTRE_SUBSCRIPION_SUCCESS';
export const MOVE_COST_CENTRE_SUBSCRIPION_FAILURE = 'MOVE_COST_CENTRE_SUBSCRIPION_FAILURE';

export const CREATE_THRESHOLD = 'CREATE_THRESHOLD';
export const CREATE_THRESHOLD_SUCCESS = 'CREATE_THRESHOLD_SUCCESS';
export const CREATE_THRESHOLD_FAILURE = 'CREATE_THRESHOLD_FAILURE';

export const UPDATE_THRESHOLD = 'UPDATE_THRESHOLD';
export const UPDATE_THRESHOLD_SUCCESS = 'UPDATE_THRESHOLD_SUCCESS';
export const UPDATE_THRESHOLD_FAILURE = 'UPDATE_THRESHOLD_FAILURE';

export const APPLY_THRESHOLD = 'APPLY_THRESHOLD';
export const APPLY_THRESHOLD_SUCCESS = 'APPLY_THRESHOLD_SUCCESS';
export const APPLY_THRESHOLD_FAILURE = 'APPLY_THRESHOLD_FAILURE';

export const UNAPPLY_THRESHOLD = 'UNAPPLY_THRESHOLD';
export const UNAPPLY_THRESHOLD_SUCCESS = 'UNAPPLY_THRESHOLD_SUCCESS';
export const UNAPPLY_THRESHOLD_FAILURE = 'UNAPPLY_THRESHOLD_FAILURE';

export const DELETE_THRESHOLD = 'DELETE_THRESHOLD';
export const DELETE_THRESHOLD_SUCCESS = 'DELETE_THRESHOLD_SUCCESS';
export const DELETE_THRESHOLD_FAILURE = 'DELETE_THRESHOLD_FAILURE';

export const SELECT_THRESHOLD = 'SELECT_THRESHOLD';
export const DESELECT_THRESHOLD = 'DESELECT_THRESHOLD';

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILURE = 'GET_CURRENCIES_FAILURE';

export const UPDATE_COST_CENTRE_PERMISSIONS = 'UPDATE_COST_CENTRE_PERMISSIONS';
export const UPDATE_COST_CENTRE_PERMISSIONS_SUCCESS = 'UPDATE_COST_CENTRE_PERMISSIONS_SUCCESS';
export const UPDATE_COST_CENTRE_PERMISSIONS_FAILURE = 'UPDATE_COST_CENTRE_PERMISSIONS_FAILURE';

