import React from 'react';
import ReactDOM from 'react-dom';
import App from './main/App';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { loadState, saveState } from './utility/localStorage';
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';

import './index.css';

const sagaMiddleware = createSagaMiddleware();
const localStorageState = loadState();
const initialState = {};

if (localStorageState !== undefined) {
    initialState.account = localStorageState.account;
}

const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(sagaMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );
  
store.subscribe(() => {
    saveState({
      account: store.getState().account,
    });
});

sagaMiddleware.run(rootSaga);

const instance = createInstance({
  urlBase: 'https://live.matomo.jisc.ac.uk/',
  siteId: 15,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  }
});

ReactDOM.render(
  <MatomoProvider value={instance}>
      <Provider store={store}>
          <Router>
              <App/>
          </Router>
      </Provider>
    </MatomoProvider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
