import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from "@material-ui/core/Typography/Typography";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { styles } from './Drawer.styles';
import logo from './logo.svg';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import TollIcon from '@material-ui/icons/Toll';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReportsIcon from '@material-ui/icons/Timeline';
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpIcon from '@material-ui/icons/Help';

import { NavLink } from 'react-router-dom';

import { ROLE } from "../../config/role";
import { PERMISSION } from "../../config/role"


class DrawerComponent extends React.Component {
	render() {
		const { classes } = this.props;
		const { role, isLoggedIn } = this.props.account;

		const menuItems = [
			{
				linkText: 'Home',
				link: '/',
				icon: <HomeOutlinedIcon className={classes.icon} />,
				role: 'all',
				requiresLogin: false
			},
			{
				linkText: 'Reports',
				link: '/reports',
				icon: <ReportsIcon className={classes.icon} />,
				role: 'all',
				requiresLogin: true
			},
			{
				linkText: 'Cost Centre',
				link: '/cost-centre',
				icon: <TollIcon className={classes.icon}/>,
				role: 'all',
				requiresLogin: true
			},
			{
				linkText: 'Users',
				link: '/users',
				icon: <PermIdentityIcon className={classes.icon} />,
				role:  'CustomerAdmin',
				requiresLogin: true
			},
			{
				linkText: 'Downloads',
				link: '/downloads',
				icon: <GetAppIcon className={classes.icon} />,
				role:  'all',
				requiresLogin: true
			},
			{
				linkText: 'FAQs',
				link: '/faq',
				icon: <HelpIcon className={classes.icon} />,
				role:  'all',
				requiresLogin: false
			}
		];

		const filteredMenuItems = menuItems.filter(item => {
			console.log(role);
			const correctRole = item.role === role || item.role === 'all' || role === ROLE.JISC_ADMIN;
			const correctLogin = !item.requiresLogin || item.requiresLogin === isLoggedIn;

			return correctRole && correctLogin;

		});

		return (
			<Drawer
			className={classes.drawer}
			variant="permanent"
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor="left"
			>
				<div className={classes.toolbar}>
					<img src={logo} className={classes.appLogo} alt="logo" />
					<Typography variant="h4" className={classes.title}>
						Cloud Portal
					</Typography>
				</div>
			<List className={classes.menu}>
				{filteredMenuItems.map((item, index) => (
					<ListItem
						button 
						component={NavLink} 
						to={item.link} 
						key={index} 
						exact
						activeClassName={classes.listItemButtonActive}
						classes={{root: classes.listItemButton}}
						className={item.className}
					>
						<ListItemIcon classes={{ root: classes.menuListItem }}>
							{item.icon}
						</ListItemIcon>
						<ListItemText primary={item.linkText} classes={{ primary: classes.menuListItemText }}/>
					</ListItem>
				))}
			</List>
		</Drawer>
		);
	}
}

const mapStateToProps = (state) => ({
	account: state.account,
});

export default compose(
	withStyles(styles),
	connect(mapStateToProps)
)(DrawerComponent);