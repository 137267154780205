import React, { Fragment, useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Reports.styles';
import {Box} from '@material-ui/core';
import CreditsSpendLineChart from '../Charts/CreditsSpendLineChart/CreditsSpendLineChart';
import SubscriptionsDailyBarChart from '../Charts/SubscriptionsDailyBarChart/SubscriptionsDailyBarChart';
import SubscriptionsMonthlyBarChart from '../Charts/SubscriptionsMonthlyBarChart/SubscriptionsMonthlyBarChart';

import Paper from '@material-ui/core/Paper';
import TitleBar from '../TitleBar/TitleBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getCustomers } from '../../actions/customerActions';
import { getCostCentres, getCostCentreDetailed } from '../../actions/costCentreActions';

const Reports = (props) => {

  const { classes, customers, costCentres, getCostCentreDetailed, account } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [customer, setCustomer] = useState('');

  useEffect(() => {
    if (!props.costCentres.isLoaded){
      props.getCostCentres();
    }
    if (!props.customers.isLoaded && account.role === "JiscAdmin"){
      props.getCustomers();
    }
  }, []);

  useEffect(() => {
    if (account.role === "JiscAdmin") {
      return;
    }
    if (costCentres.overview.length > 0 && customer === '') {
      setCustomer(account.CustomerSK);
      loadAllCostCentres(account.CustomerSK);
    }
  }, [costCentres]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const StyledTabs = withStyles({
    indicator: {
      display: 'none',
    },
    root: {
      backgroundColor: '#57778E',
      marginTop: 55,
    },
  })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
  
  const StyledTab = withStyles(theme => ({
    root: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSizes.small,
      color: '#fff',
      borderRight: '1px solid',
      borderRightColor: '#fff',
      opacity: 1,
      backgroundColor: '#57778E',
      paddingTop: theme.spacingDefaults.triple,
      paddingBottom: theme.spacingDefaults.triple,
    },
    selected: {
      color: '#000',
      backgroundColor: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
    },
  }))(props => <Tab {...props} />);

  let customerSelectMarkup = '';
  
  const handleChangeCustomer = event => {
    setCustomer(event.target.value);
    loadAllCostCentres(event.target.value);
  };

  const loadAllCostCentres = (customerSK) => {

    costCentres.overview
      .filter(costCentre => costCentre.CustomerSK === customerSK)
      .map(customer => {
        if (!customer.isDetailLoaded) {
          getCostCentreDetailed(customer.CostCentreKey);
        }
      });
  }

  if (account.role === "JiscAdmin") {
    customerSelectMarkup = 
    <Grid container item xs={6} className={classes.alignRight}>

        <InputLabel htmlFor="Customer" className={classes.labelInline}>Customer</InputLabel>

        <Select value={customer} variant="outlined" label="Customer" id="Customer" onChange={handleChangeCustomer}>
        {customers.data.map(customer => (
            <MenuItem value={customer.CustomerSK} key={customer.CustomerSK}>
            {customer.CustomerName}
            </MenuItem>
        ))}
        </Select>

    </Grid> 
  }

  return (
    <Fragment>
      <TitleBar pageName='Reports' />

      <Grid container spacing={3}>

        <Grid container item xs={6}>
          {/* <p>Credits</p> */}
        </Grid>

        {customerSelectMarkup}

      </Grid>
      <StyledTabs value={tabIndex} onChange={handleChange} aria-label="content tab" square component={Paper}>
        <StyledTab label="Subscription/Account (30 Days)" />
        <StyledTab label="Subscription/Account (Monthly)" />
        {(account.role === 'JiscAdmin' || account.role === 'CustomerAdmin') && <StyledTab label="Account Balance (30 Days)" />}
      </StyledTabs>

      <Grid container spacing={3}>

        <Grid item xs={12}>
          
          <Paper square elevation={3} className={classes.paper}>

            <Box mb={5} hidden={tabIndex !== 0}>
              <SubscriptionsDailyBarChart selectedCustomer={customer} />
            </Box>

            <Box mb={5} hidden={tabIndex !== 1}>
              <SubscriptionsMonthlyBarChart selectedCustomer={customer} />
            </Box>

            <Box mb={5} hidden={tabIndex !== 2}>
              <CreditsSpendLineChart selectedCustomer={customer} />
            </Box>

          </Paper>

        </Grid>
        
      </Grid>
    </Fragment>
  );
}

const mapDispatchToProps = {
  getCustomers,
  getCostCentres,
  getCostCentreDetailed,
};

const mapStateToProps = (state) => ({
  account: state.account,
  customers: state.customers,
  costCentres: state.costCentres,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
)(Reports);