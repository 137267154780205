export const loadState = () => {
  try {
    const state = localStorage.getItem('jisc-cloud-billing');
    if (state === null) {
      return undefined;
    }
    return JSON.parse(state);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serialized = JSON.stringify(state);
    localStorage.setItem('jisc-cloud-billing', serialized);
  } catch (err) {
    console.error(err);
  }
};