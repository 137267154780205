import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Home.styles';
import TitleBar from '../TitleBar/TitleBar';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography/Typography";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import homeFeatureImage from './home_feature.png';
import pdfDownloadBlue from './pdf_image_blue.png';
import pdfDownloadGreen from './pdf_image_green.png';
import feedbackIcon from './feedback.png';
import jiscLogo from '../Drawer/logo.svg';

class Home extends React.Component {
  
  render () {
    const { 
      classes,
    } = this.props;

    const mailLink = 'mailto:billingplatform@jisc.ac.uk';

    const { isLoggedIn, userName } = this.props.account;

    return (
      <div>
        <TitleBar pageName='Home' />
        
        {isLoggedIn &&
          <Grid container spacing={3} style={{marginBottom: '20px'}}>
            <Grid item xs={12}>
              <Paper elevation={0} className={`${classes.paper} ${classes.welcomeBanner}`}>
                <Typography variant="h5" className={classes.welcomeBannerText}>Welcome {userName}</Typography>
                <Typography className={classes.welcomeText}>
                  Thank you for logging into the online portal.
                  If this is your first time logging in, please see the content below to assist you with the many features available within the system.
                  If you have any questions or issues at all, please contact us at <a href={mailLink}>billingplatform@jisc.ac.uk</a>.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        }

        <Paper square elevation={3} className={classes.paper}>
          <Grid container spacing={0}>
            <Grid container item xs={12} md={8} spacing={3}>

              <Grid item xs={12}>
                <div className={`${classes.paddedContent} ${classes.introContainer}`}>
                  <img src={jiscLogo} className={classes.appLogo} alt="Jisc Logo" />
                  <Typography variant="h2" className={`${classes.fullWidth} ${classes.introText}`}>Our Services</Typography>
                </div>
              </Grid>

              <Grid item sm={12} md={6}>
                <div className={`${classes.paddedContent}`}>
                  <Typography className={classes.moreInformation}>As your trusted partner and reseller for Amazon Web Services, Azure and Microsoft Office 365, we can ensure you get the best value on cloud solutions.</Typography>  

                  <Button variant="outlined" color="primary">
                    Find out more
                  </Button>
                </div>
              </Grid>

              <Grid item sm={12} md={6} style={{justifyContent: 'center', display: 'flex'}}>
                <ul className={classes.featureList}>
                  <li className={classes.featureListItem}>
                    <Typography variant="caption">Cloud Consultancy</Typography>
                  </li>
                  <li className={classes.featureListItem}>
                    <Typography variant="caption">Professional Services</Typography>
                  </li>
                  <li className={classes.featureListItem}>
                    <Typography variant="caption">Shared Data Centre</Typography>
                  </li>
                </ul>
              </Grid>

            </Grid>

            <Grid item xs={12} md={4} style={{alignSelf: 'center'}}>
              <div className={`${classes.paddedContent} ${classes.homeFeatureImageContainer}`}>
                <img src={homeFeatureImage} className={classes.homeFeatureImage} alt="Multiple tablet computers" />
              </div>
            </Grid>
            
          </Grid>
        </Paper>
    
        <Grid container spacing={3} style={{marginTop: '20px', marginBottom: '20px'}}>
          <Grid item sm={12} md={4}>
            <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={classes.paper}>
              <Typography variant="h4" className={classes.featureTitle}>Portal Tutorial PDF</Typography>
              <Typography className={classes.featureInfo}>Download our step-by-step guide of how to use the online portal.</Typography>

              <div className={classes.featureDownloadContainer}>
                <Button variant="contained" color="primary" className={classes.featureDownloadButton}>
                  Download
                </Button>
              
                <img src={pdfDownloadBlue} className={classes.pdfDownloadImage } alt="Download Tutorial Icon" />
              </div>
            </Paper>
          </Grid>

          <Grid item sm={12} md={4}>
            <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={classes.paper}>
              <Typography variant="h4" className={`${classes.featureTitle} ${classes.featureTitleGreen}`}>Cloud Services PDF</Typography>
              <Typography className={classes.featureInfo}>Learn more about how our cloud-related services can help your business.</Typography>

              <div className={classes.featureDownloadContainer}>
                <Button variant="contained" color="primary" className={classes.featureDownloadButton}>
                  Download
                </Button>
              
                <img src={pdfDownloadGreen} className={classes.pdfDownloadImage } alt="Download Tutorial Icon" />
              </div>
            </Paper>
          </Grid>

          <Grid item sm={12} md={4}>
            <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={`${classes.paper} ${classes.paperFeedback}`}>
              <Typography variant="h4" className={`${classes.featureTitle} ${classes.featureTitleDarkGreen}`}>Feedback</Typography>
              <Typography className={classes.featureInfo}>We would love to hear your feedback on our online portal and any suggestions you have for future features which you may think will improve your experience.</Typography>

              <div className={classes.featureDownloadContainer}>
                <Button href={mailLink} variant="outlined" color="primary" className={classes.featureDownloadButton}>
                  Give Feedback
                </Button>
              
                <img src={feedbackIcon} style={{padding: '30px'}} className={classes.pdfDownloadImage} alt="Feedback Icon" />
              </div>
            </Paper>
          </Grid>
        </Grid>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default compose(
  withRouter,
  withStyles(styles),  
  connect(
    mapStateToProps,
  ),
)(Home);