import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { styles } from './Header.styles';
import { logout } from '../../actions/authActions';
import AccountHeader from '../AccountHeader/AccountHeader';
import PropTypes from 'prop-types';


class Header extends React.Component {

	handleLogin = (entityId) => {
		console.log(entityId)
		this.useSSO(entityId);
	};

	handleLogout = () => {
		this.props.logout();
	};



	render() {
		const {classes} = this.props;
		const {isLoggedIn, userName} = this.props.account;

		return (
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<AccountHeader isLoggedIn={isLoggedIn} userName={userName} logout={this.handleLogout}/>
				</Toolbar>
			</AppBar>
		);
	}
}

Header.propTypes = {
	account: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	account: state.account,
});

const mapDispatchToProps = {
	logout
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(Header);