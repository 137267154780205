import {
  GET_COST_CENTRES,
  GET_COST_CENTRES_SUCCESS,
  GET_COST_CENTRES_FAILURE,

  GET_COST_CENTRE_DETAILED,
  GET_COST_CENTRE_DETAILED_SUCCESS,
  GET_COST_CENTRE_DETAILED_FAILURE,

  GET_COST_CENTRE_USERS,
  GET_COST_CENTRE_USERS_SUCCESS,
  GET_COST_CENTRE_USERS_FAILURE,
  
  CREATE_COST_CENTRE_SUCCESS,
  CREATE_COST_CENTRE_FAILURE,

  UPDATE_COST_CENTRE_SUCCESS,
  UPDATE_COST_CENTRE_FAILURE,

  DELETE_COST_CENTRE_SUCCESS,
  
  SET_ACTIVE_COST_CENTRE,

  MOVE_COST_CENTRE_SUBSCRIPION_SUCCESS,
  MOVE_COST_CENTRE_SUBSCRIPION_FAILURE,

  CREATE_THRESHOLD_SUCCESS,
  CREATE_THRESHOLD_FAILURE,

  UPDATE_THRESHOLD_SUCCESS,
  UPDATE_THRESHOLD_FAILURE,

  APPLY_THRESHOLD_SUCCESS,
  APPLY_THRESHOLD_FAILURE,

  UNAPPLY_THRESHOLD_SUCCESS,
  UNAPPLY_THRESHOLD_FAILURE,

  DELETE_THRESHOLD_SUCCESS,
  DELETE_THRESHOLD_FAILURE,

  SELECT_THRESHOLD,
  DESELECT_THRESHOLD,

  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,

  UPDATE_COST_CENTRE_PERMISSIONS,
  UPDATE_COST_CENTRE_PERMISSIONS_SUCCESS,
  UPDATE_COST_CENTRE_PERMISSIONS_FAILURE,
} from '../actions/costCentreActionTypes';

export const initialState = {
  isLoaded: false,
  isLoading: false,
  isLoadingFailed: false,
  isLoadingDetailed: false,
  overview: [],
  detailed: [],
  activeCostCentre: [],
  selectedThreshold: null,
  currenciesLoaded: false,
  currencies: [],
  updatingCostCentres: false,
};

export const costCentres = (state = initialState, action) => {

  switch (action.type) {
    case SET_ACTIVE_COST_CENTRE:
      return {
        ...state,
        activeCostCentre: action.payload.data,
      };
    case GET_COST_CENTRES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COST_CENTRES_SUCCESS:
      return {
        ...state,
        overview: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    case GET_COST_CENTRES_FAILURE:
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
          isLoadingFailed: true,
        };
    case GET_COST_CENTRE_DETAILED:
        return {
          ...state,
          isLoadingDetailed: true,
        };
    case GET_COST_CENTRE_DETAILED_SUCCESS:
        const nextCostCentreData = state.overview.map((item) => {
          if (item.CostCentreKey == action.payload.data.CostCentreKey) {
            let itemModified = {
              ...item,
              subscriptions: action.payload.data.subscriptions,
              thresholds: action.payload.data.thresholds,
              isDetailLoaded: true,
            }

            return itemModified;
          }
          return item;
        });

        let activeCostCentreData = state.activeCostCentre;
        if (state.activeCostCentre.CostCentreKey == action.payload.data.CostCentreKey) {
          activeCostCentreData = action.payload.data;
        }

        return {
          ...state,
          overview: nextCostCentreData,
          activeCostCentre: activeCostCentreData,
          isLoadingDetailed: false,
        };
    case GET_COST_CENTRE_DETAILED_FAILURE:
        return {
          ...state,
          isLoadingDetailed: false,
        };
    case GET_COST_CENTRE_USERS:
        return {
          ...state,
        };
    case GET_COST_CENTRE_USERS_SUCCESS:
      const nextCostCentreDataCahnge = state.overview.map((item) => {
        if (item.CostCentreKey == action.payload.data.costCentreId) {
          let itemModified = {
            ...item,
            users: action.payload.data.users,
            isUsersLoaded: true,
          }

          return itemModified;
        }
        return item;
      });

      if (action.payload.data.costCentreId == state.activeCostCentre.CostCentreKey) {
        return {
          ...state,
          overview: nextCostCentreDataCahnge,
          isLoadingUsers: false,
          activeCostCentre: {
            ...state.activeCostCentre,
            users: action.payload.data.users,
            isUsersLoaded: true,
          }
        };
      }

      return {
        ...state,
        overview: nextCostCentreDataCahnge,
        isLoadingUsers: false,
      };
    case GET_COST_CENTRE_USERS_FAILURE:
      return {
        ...state,
      };
    case CREATE_COST_CENTRE_SUCCESS:
      const { data } = action.payload.data;
      
      return {
        ...state,
        overview: [...state.overview, data],
      };
    case CREATE_COST_CENTRE_FAILURE:
      return {
        ...state,
      };
    case UPDATE_COST_CENTRE_SUCCESS:

      const overviewWithUpdatedCostCentre = state.overview.map((item) => {
        if (item.CostCentreKey == action.payload.data.CostCentreKey) {
          item.Name = action.payload.data.Name;
          return item;
        }
        return item;
      });

      return {
        ...state,
        overview: overviewWithUpdatedCostCentre,
      }
    case UPDATE_COST_CENTRE_FAILURE:
      return {
        ...state
      }
    case DELETE_COST_CENTRE_SUCCESS:
      const costCentreDeletedState = state.overview.filter( costCentre => costCentre.CostCentreKey !== action.payload.data);

      return {
        ...state,
        overview: costCentreDeletedState,
      };  
    case MOVE_COST_CENTRE_SUBSCRIPION_SUCCESS:
      const { CostCentreKey, SubscriptionKey } = action.payload.data;

      const movedSub = state.activeCostCentre.subscriptions.find(sub => sub.SubscriptionSK == SubscriptionKey);
      
      const overviewWithMovedSub = state.overview.map((item) => {
        //Remove sub
        if (item.CostCentreKey == state.activeCostCentre.CostCentreKey) {
          const updatedSubs = item.subscriptions.filter( sub => sub.SubscriptionSK !== SubscriptionKey);

          let itemModified = {
            ...item,
            subscriptions: updatedSubs,
          }
          return itemModified;
        }

        //Add sub
        if (item.isDetailLoaded && item.CostCentreKey == CostCentreKey) {
          let movedSub = {
            ...movedSub,
            thresholds: []
          }
          
          let itemModified = {
            ...item,
            subscriptions: [
              ...item.subscriptions,
              movedSub,
            ]
          }
          return itemModified;
        }

        return item;
      });

      return {
        ...state,
        overview: overviewWithMovedSub,
      };
    case MOVE_COST_CENTRE_SUBSCRIPION_FAILURE:
      return {
        ...state,
      };
    case CREATE_THRESHOLD_SUCCESS:
      const createThresholdCostCentreIndex = state.overview.findIndex(costCentre => costCentre.CostCentreKey == action.payload.data.CostCentreKey);      
      const detailLoaded = state.overview[createThresholdCostCentreIndex].isDetailLoaded || false;

      if (detailLoaded) {
        const overviewWithCreatedCentre = state.overview.map((item) => {
          if (item.CostCentreKey == action.payload.data.CostCentreKey) {
            let itemModified = {
              ...item,
              thresholds: [
                ...item.thresholds,
                action.payload.data
              ]
            }
  
            return itemModified;
          }
          return item;
        });

        return {
          ...state,
          overview: overviewWithCreatedCentre,
      };
      } else {
        return {
          ...state,
        };
      }
    case CREATE_THRESHOLD_FAILURE:
      return {
        ...state
      }
    case DELETE_THRESHOLD_SUCCESS:

      const thresholdDeletedState = state.overview.map((item) => {
        if (item.CostCentreKey == action.payload.data.CostCentreKey) {
          return {
            ...item,
            thresholds: item.thresholds.filter( t => t.ThresholdKey !== action.payload.data.ThresholdKey)
          };
        }
        return item;
      });

      return {
        ...state,
        overview: thresholdDeletedState,
      }
    case DELETE_THRESHOLD_FAILURE:

      return {
        ...state,
      }

    case SELECT_THRESHOLD:
      return {
        ...state,
        selectedThreshold: action.payload.data,
      }
    case DESELECT_THRESHOLD:
      return {
        ...state,
        selectedThreshold: null,
      }
    case UPDATE_THRESHOLD_SUCCESS:
  
      const overviewWithUpdatedCentre = state.overview.map((item) => {
        if (item.CostCentreKey == action.payload.data.CostCentreKey) {

          const updatedThresholdIndex = item.thresholds.findIndex(threshold => threshold.ThresholdKey == action.payload.data.ThresholdKey);
          item.thresholds[updatedThresholdIndex] = action.payload.data;

          let itemModified = {
            ...item,
            thresholds: [
              ...item.thresholds
            ]
          }

          return itemModified;
        }
        return item;
      });

      return {
        ...state,
      }
    case UPDATE_THRESHOLD_FAILURE:
      return {
        ...state
      }
    case APPLY_THRESHOLD_SUCCESS: 

    let updatedSubscriptionObject = null;

      const overviewWithAddedThrehsold = state.overview.map((item) => {

        if (item.CostCentreKey == action.payload.data.costCentreKey) {

          updatedSubscriptionObject = item.subscriptions.map((sub) => {

            if (sub.SubscriptionSK == action.payload.data.data.SubscriptionKey) {

              let itemModified = {
                ...sub,
                thresholds: [
                  ...sub.thresholds,
                  action.payload.data.threshold
                ]
              }

              return itemModified;
            }

            return sub;
            
          });

          let itemModified = {
            ...item,
            subscriptions: updatedSubscriptionObject
          }

          return itemModified;
        }
        return item;
      });

      let activeCostCentreDataAddedThreshold = state.activeCostCentre;
      
      if (state.activeCostCentre.CostCentreKey == action.payload.data.costCentreKey) {

        activeCostCentreDataAddedThreshold = {
          ...state.activeCostCentre,
          subscriptions: updatedSubscriptionObject
        }

      }

      return {
        ...state,
        overview: overviewWithAddedThrehsold,
        activeCostCentre: activeCostCentreDataAddedThreshold
      }
    case APPLY_THRESHOLD_FAILURE: 
      return {
        ...state
      }

    case UNAPPLY_THRESHOLD_SUCCESS: 

    let updatedSubscriptionThreshRemovedObject = null;

      const overviewWithRemovedThrehsold = state.overview.map((item) => {

        if (item.CostCentreKey == action.payload.data.costCentreKey) {

          updatedSubscriptionThreshRemovedObject = item.subscriptions.map((sub) => {

            if (sub.SubscriptionSK == action.payload.data.data.SubscriptionKey) {
              const updatedThresholds = sub.thresholds.filter( threshold => threshold.ThresholdKey !== action.payload.data.data.ThresholdKey);
              let itemModified = {

                ...sub,
                thresholds: updatedThresholds
              }

              return itemModified;
            }

            return sub;
            
          });

          let itemModified = {
            ...item,
            subscriptions: updatedSubscriptionThreshRemovedObject
          }

          return itemModified;
        }
        return item;
      });
      
      let activeCostCentreRemovedThreshold = state.activeCostCentre;
      
      if (state.activeCostCentre.CostCentreKey == action.payload.data.costCentreKey) {
        activeCostCentreRemovedThreshold = {
          ...state.activeCostCentre,
          subscriptions: updatedSubscriptionThreshRemovedObject
        }
      }

      return {
        ...state,
        overview: overviewWithRemovedThrehsold,
        activeCostCentre: activeCostCentreRemovedThreshold
      }
    case UNAPPLY_THRESHOLD_FAILURE: 
      return {
        ...state
      }
    case GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currenciesLoaded: true,
        currencies: action.payload.data
      };
    case GET_CURRENCIES_FAILURE:
      return {
        ...state,
      };

    case UPDATE_COST_CENTRE_PERMISSIONS:
      return {
        ...state,
        updatingCostCentres: true,
      };
    case UPDATE_COST_CENTRE_PERMISSIONS_SUCCESS:
      let overviewWithUpdatedUserPermissions = state.overview.map((item) => {
        if (item.CostCentreKey == action.payload.data.CostCentreKey) {

          let itemModified = {
            ...item,
            users: action.payload.data.userState
          }

          return itemModified;
        }
        return item;
      });

      return {
        ...state,
        overview: overviewWithUpdatedUserPermissions,
        updatingCostCentres: false,
      }
    case UPDATE_COST_CENTRE_PERMISSIONS_FAILURE:
      return {
        ...state,
        updatingCostCentres: false,
      }
    default:
      return state;
    };
    
};
