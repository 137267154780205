import { fork, all } from 'redux-saga/effects';
import authSaga from '../sagas/authSaga';
import costCentreSaga from '../sagas/costCentreSaga';
import customerSaga from '../sagas/customerSaga';
import expenditure from '../sagas/expenditure';

export default function *rootSaga () {
  yield all([
    fork(authSaga),
    fork(customerSaga),
    fork(costCentreSaga),
    fork(expenditure)
  ]);
}