import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Downloads.styles';
import TitleBar from '../TitleBar/TitleBar';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography/Typography";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import pdfIcon from './pdf_icon.png';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { LoadingSpinner }  from "../LoadingSpinner";

import { getFileDownloadList, getFileDownload } from "../../utility/api";

const Downloads = (props) => {
  
  const getStripedStyle = (index) => {
    return { background: index % 2 ? '#F3F5F5' : 'none' };
  }

  const { 
    classes,
  } = props;

  const token = props.account.jwt;

  const [fileList, setFileList] = useState([]);
  const [fileListLoaded, setFileListLoaded] = useState(false);

  useEffect(()  => {

    getFileDownloadList(token).then(data => {
      setFileList(data);
      setFileListLoaded(true);
    });

  }, []);

  const handleFileDownload = (fileName) => {

    getFileDownload(token, fileName).then(response => {

      const file = new Blob(
        [response], 
        {type: 'application/pdf'}
      );

      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `${fileName}`);

      document.body.appendChild(link);
      link.click();
    });

  }

  return (
    <div>
      <TitleBar pageName='Downloads' />
      
      <Grid container spacing={3} style={{marginBottom: '20px'}}>
        <Grid item xs={12}>

          {!fileListLoaded &&
            <LoadingSpinner/>
          }

          {fileListLoaded &&
            <TableContainer component={Paper} square elevation={0}>
                <Table aria-label="Downloads">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{width: '5%'}} className={`${classes.bottomBorder} ${classes.PushLeft}`}></TableCell>
                      <TableCell className={classes.bottomBorder}>Document Name</TableCell>
                      <TableCell className={classes.bottomBorder} align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>

                  {fileList.map((fileName, index) => (
                    fileName ? 
                      <TableBody key={index}>
                        <TableRow key={index} style={{ padding: '5px 20px', height: 25, ...getStripedStyle(index) }}>

                          <TableCell className={`${classes.removeBorder} ${classes.PushLeft}`} align="center">
                            <img src={pdfIcon} className={classes.pdfIcon} alt="logo" />
                          </TableCell>

                          <TableCell className={`${classes.removeBorder} ${classes.PushLeft}`}>
                            <Typography>{fileName.split('/').pop()}</Typography>
                          </TableCell>

                          <TableCell align="right" className={classes.removeBorder}>

                            <Button variant="contained" color="primary" onClick={() => handleFileDownload(fileName)}>
                              Download
                            </Button>

                          </TableCell>
                        </TableRow>
                      </TableBody> : null
                  ))}
                  
                </Table>
            </TableContainer>
          }

        </Grid>
      </Grid>

    </div>
  );
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
  ),
)(Downloads);