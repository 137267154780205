import { GET_EXPENDITURE, GET_EXPENDITURE_SUCCESS, GET_EXPENDITURE_FAILURE } from './expenditureActionTypes';

export const getExpenditure = () => ({
    type: GET_EXPENDITURE
});

export const getExpenditureSuccess = data => ({
    type: GET_EXPENDITURE_SUCCESS,
    payload: {
        data
    }
});

export const getExpenditureFailure = message => ({
    type: GET_EXPENDITURE_FAILURE,
    payload: {
        message
    }
});