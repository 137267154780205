import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { 
  getCostCentresSuccess, 
  getCostCentresFailure,
  createCostCentreSuccess,
  createCostCentreFailure,
  updateCostCentreSuccess,
  updateCostCentreFailure,
  deleteCostCentreSuccess,
  deleteCostCentreFailure,
  getCostCentreDetailedSuccess,
  getCostCentreDetailedFailure,
  moveSubscriptionSuccess,
  moveSubscriptionFailure,
  getCostCentreUsersSuccess,
  getCostCentreUsersFailure,
  createThresholdSuccess,
  createThresholdFailure,
  deleteThresholdSuccess,
  deleteThresholdFailure,
  updateThresholdSuccess,
  updateThresholdFailure,
  applyThresholdSuccess,
  applyThresholdFailure,
  unapplyThresholdSuccess,
  unapplyThresholdFailure,
  getCurrenciesSuccess,
  getCurrenciesFailure,
  updateCostCentrePermissionsSuccess,
  updateCostCentrePermissionsFailure,

} from '../actions/costCentreActions';

import { 
  createNotification,
  closeAddCostCentreModal,
  closeEditSubscriptionModal,
  closeAddThresholdModal,
  closeDeleteCostCentreConfirmModal,
  closeEditCostCentreModal,
  closeEditCostCentreUsersModal,
} from '../actions/uiActions';

import { 
  GET_COST_CENTRES,
  GET_COST_CENTRE_DETAILED,
  CREATE_COST_CENTRE,
  UPDATE_COST_CENTRE,
  DELETE_COST_CENTRE,
  MOVE_COST_CENTRE_SUBSCRIPION,
  GET_COST_CENTRE_USERS,
  CREATE_THRESHOLD,
  DELETE_THRESHOLD,
  APPLY_THRESHOLD,
  UNAPPLY_THRESHOLD,
  UPDATE_THRESHOLD,
  GET_CURRENCIES,
  UPDATE_COST_CENTRE_PERMISSIONS,
 } from '../actions/costCentreActionTypes';

import { 
  getCostCentres,
  postCostCentre,
  updateCostCentre,
  deleteCostCentre,
  getCostCentreDetails,
  updateSubscription,
  deleteSubscriptionRelation,
  getUsersForCostCentre,
  postThreshold,
  applyUser,
  deapplyUser,
  deleteThreshold,
  postThresholdUpdate,
  getCurrencies,
  postUserPermission,
  deleteUserPermission,
} from "../utility/api";

export default function *costCentreSaga () {
  yield all([
    takeEvery(GET_COST_CENTRES, getCostCentresOverview),
    takeEvery(GET_COST_CENTRE_DETAILED, getCostCentreDetailed),
    takeLatest(CREATE_COST_CENTRE, createCostCentre),
    takeLatest(MOVE_COST_CENTRE_SUBSCRIPION, moveCostCentreSubscription),
    takeEvery(GET_COST_CENTRE_USERS, getCostCentreUsers),
    takeLatest(CREATE_THRESHOLD, createThreshold),
    takeLatest(DELETE_THRESHOLD, removeThreshold),
    takeLatest(UPDATE_THRESHOLD, updateThreshold),
    takeLatest(GET_CURRENCIES, getCurrenciesAsync),
    takeEvery(APPLY_THRESHOLD, applyThreshold),
    takeEvery(UNAPPLY_THRESHOLD, unapplyThreshold),
    takeLatest(UPDATE_COST_CENTRE, updateCostCentreAsync),
    takeLatest(DELETE_COST_CENTRE, deleteCostCentreAsync),
    takeLatest(UPDATE_COST_CENTRE_PERMISSIONS, updateCostCentrePermissions),
  ]);
}

function *getCostCentresOverview (action) {
  try {
    const token = yield select(state => state.account.jwt);
    const data = yield call(getCostCentres, token);
    
    yield put(getCostCentresSuccess(data));
  } catch (error) {
    yield put(getCostCentresFailure(error));
  }
}

function *getCostCentreDetailed (action) {
  try {
    const { costCentreId } = action.payload;
    const token = yield select(state => state.account.jwt);
    const data = yield call(getCostCentreDetails, token, costCentreId);

    yield put(getCostCentreDetailedSuccess(data));
  } catch (error) {
    yield put(getCostCentreDetailedFailure(error));
  }
}

function *getCostCentreUsers (action) {
  try {
    const token = yield select(state => state.account.jwt);
    const { costCentreId } = action.payload;
    const users = yield call(getUsersForCostCentre, token, costCentreId);

    const formattedData = {
      costCentreId,
      users,
    }

    yield put(getCostCentreUsersSuccess(formattedData));
  } catch (error) {
    yield put(getCostCentreUsersFailure(error));
  }
}

function *createCostCentre (action) {
  try {
    const token = yield select(state => state.account.jwt);
    const data = yield call(postCostCentre, action.payload.data, token);

    let successNotification = {
      message: 'Cost Centre added successfully',
      type: 'success'
    }
    
    yield put(createCostCentreSuccess(data));
    yield put(createNotification(successNotification));
    yield put(closeAddCostCentreModal());
    
  } catch (error) {

    let errorNotification = {
      message: error.errorMessage || 'Failed to create cost centre',
      type: 'error'
    }

    yield put(createCostCentreFailure(error));
    yield put(createNotification(errorNotification));
    yield put(closeAddCostCentreModal());
  }
}

function *updateCostCentreAsync (action) {
  try {
    const token = yield select(state => state.account.jwt);
    const {CostCentreKey} = action.payload.data;
    
    yield call(updateCostCentre, action.payload.data, CostCentreKey, token);

    let successNotification = {
      message: 'Cost Centre updated successfully',
      type: 'success'
    }
    
    yield put(updateCostCentreSuccess(action.payload.data));
    yield put(createNotification(successNotification));
    yield put(closeEditCostCentreModal());
    
  } catch (error) {

    let errorNotification = {
      message: error.errorMessage || 'Failed to update cost centre',
      type: 'error'
    }

    yield put(updateCostCentreFailure(error));
    yield put(createNotification(errorNotification));
    yield put(closeEditCostCentreModal());
  } 
}

function *deleteCostCentreAsync (action) {
  try {
    const token = yield select(state => state.account.jwt);
    yield call(deleteCostCentre, action.payload.data, token);

    let successNotification = {
      message: 'Cost Centre deleted successfully',
      type: 'success'
    }

    yield put(deleteCostCentreSuccess(action.payload.data));
    yield put(createNotification(successNotification));
    yield put(closeDeleteCostCentreConfirmModal());
  } catch (error) {
    let errorNotification = {
      message: error.errorMessage || 'Failed to delete Cost Centre',
      type: 'error'
    }

    yield put(deleteCostCentreFailure(error));
    yield put(createNotification(errorNotification));
  }
}

function *moveCostCentreSubscription (action) {
  try {
    const token = yield select(state => state.account.jwt);

    yield call(updateSubscription, action.payload.data, token);

    let successNotification = {
      message: 'Subscription updated successfully',
      type: 'success'
    }
    
    yield put(moveSubscriptionSuccess(action.payload.data));
    yield put(createNotification(successNotification));
    yield put(closeEditSubscriptionModal());
  } catch (error) {
    let errorNotification = {
      message: error.errorMessage || 'Failed to update subscription',
      type: 'error'
    }
    
    yield put(moveSubscriptionFailure(error));
    yield put(createNotification(errorNotification));
  }
}

function *createThreshold (action) {
  try {
    const token = yield select(state => state.account.jwt);
    const thresholdCreateResponse = yield call(postThreshold, action.payload.data, token);
    const users = action.payload.data.users;

    let thresholdResponseWithUsers = {
      ...thresholdCreateResponse.data,
      users,
    }

    if (users.length) {

      yield all(users.map(
        user => call(
          applyUser,
          {
            "UserId": user.id,
            "ThresholdKey": thresholdCreateResponse.data.ThresholdKey,
          },
          token
        )
      ));
    }

    let successNotification = {
      message: 'Threshold created successfully',
      type: 'success'
    }

    yield put(createThresholdSuccess(thresholdResponseWithUsers));
    yield put(createNotification(successNotification));
    yield put(closeAddThresholdModal());
    
  } catch (error) {

    let errorNotification = {
      message: error.errorMessage || 'Failed to create threshold',
      type: 'error'
    }

    yield put(createThresholdFailure(error));
    yield put(createNotification(errorNotification));
  }
}

function* removeThreshold(action) {
  try {
    const token = yield select(state => state.account.jwt);
    yield call(deleteThreshold, action.payload.data.ThresholdKey, token);

    let successNotification = {
      message: 'Threshold deleted successfully',
      type: 'success'
    }

    yield put(deleteThresholdSuccess(action.payload.data));
    yield put(createNotification(successNotification));
  } catch (error) {
    let errorNotification = {
      message: error.errorMessage || 'Failed to delete threshold',
      type: 'error'
    }

    yield put(deleteThresholdFailure(error));
    yield put(createNotification(errorNotification));
  }
}

function *updateThreshold (action) {
  try {
    const { users, usersToAdd, usersToRemove } = action.payload.data;
    const token = yield select(state => state.account.jwt);
    const thresholdCreateResponse = yield call(postThresholdUpdate, action.payload.data, action.payload.data.ThresholdKey, token);

    if (usersToAdd.length) {
      yield all(usersToAdd.map(
        user => call(
          applyUser,
          {
            "UserId": user.id,
            "ThresholdKey": action.payload.data.ThresholdKey
          },
          token
        )
      ));
    }

    if (usersToRemove.length) {
      yield all(usersToRemove.map(
        user => call(
          deapplyUser,
          {
            "UserId": user.id,
            "ThresholdKey": action.payload.data.ThresholdKey
          },
          token
        )
      ));
    }

    let thresholdResponseWithUsers = {
      ...thresholdCreateResponse.data,
      users,
    }

    let successNotification = {
      message: 'Threshold updated successfully',
      type: 'success'
    }

    yield put(updateThresholdSuccess(thresholdResponseWithUsers));
    yield put(createNotification(successNotification));
    yield put(closeAddThresholdModal());
    
  } catch (error) {

    let errorNotification = {
      message: error.errorMessage || 'Failed to update threshold',
      type: 'error'
    }

    yield put(updateThresholdFailure(error));
    yield put(createNotification(errorNotification));
  }
}


function *applyThreshold (action) {
  try {
    const token = yield select(state => state.account.jwt);

    const response = yield call(updateSubscription, action.payload.data, token);

    let successNotification = {
      message: 'Threshold added to subscription successfully',
      type: 'success'
    }

    yield put(applyThresholdSuccess(action.payload));
    yield put(createNotification(successNotification));
    
  } catch (error) {

    let errorNotification = {
      message: error.errorMessage || 'Failed to add threshold to subscription',
      type: 'error'
    }

    yield put(applyThresholdFailure(error));
    yield put(createNotification(errorNotification));
  }
}

function *unapplyThreshold (action) {
  try {
    const token = yield select(state => state.account.jwt);
    yield call(deleteSubscriptionRelation, action.payload.data, token);

    let successNotification = {
      message: 'Threshold removed from subscription successfully',
      type: 'success'
    }

    yield put(unapplyThresholdSuccess(action.payload));
    yield put(createNotification(successNotification));
    
  } catch (error) {

    let errorNotification = {
      message: error.errorMessage || 'Failed to remove threshold from subscription',
      type: 'error'
    }

    yield put(unapplyThresholdSuccess(error));
    yield put(createNotification(errorNotification));
  }
}

function *getCurrenciesAsync (action) {
  try {
    const token = yield select(state => state.account.jwt);
    const data = yield call(getCurrencies, token);
    
    yield put(getCurrenciesSuccess(data));
  } catch (error) {
    yield put(getCurrenciesFailure(error));
  }
}


function *updateCostCentrePermissions (action) {
  try {
    const { updatedUsers, removedUsers, CostCentreKey } = action.payload.data;
    const token = yield select(state => state.account.jwt);
    
    if (updatedUsers.length) {
      yield all(updatedUsers.map(
        user => call(
          postUserPermission,
          {
            "UserId": user.id,
            "CostCentreKey": CostCentreKey,
            "PermissionLevel": user.PermissionLevel
          },
          token
        )
      ));
    }

    if (removedUsers.length) {
      yield all(removedUsers.map(
        user => call(
          deleteUserPermission,
          {
            "UserId": user.id,
            "CostCentreKey": CostCentreKey,
          },
          token
        )
      ));
    }


    let successNotification = {
      message: 'Cost Centre Users updated successfully',
      type: 'success'
    }

    yield put(updateCostCentrePermissionsSuccess(action.payload.data));
    yield put(createNotification(successNotification));
    yield put(closeEditCostCentreUsersModal());
    
  } catch (error) {

    let errorNotification = {
      message: error.errorMessage || 'Failed to update Cost Centre Users',
      type: 'error'
    }

    yield put(updateCostCentrePermissionsFailure(error));
    yield put(createNotification(errorNotification));
  }
}