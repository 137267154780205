import { combineReducers } from 'redux';
import { account } from '../reducers/authReducer';
import { customers } from '../reducers/customerReducer';
import { costCentres } from '../reducers/costCentreReducer';
import { ui } from '../reducers/uiReducer';
import { expenditure } from '../reducers/expenditureReducer';

const rootReducer = combineReducers({
	account,
	customers,
	costCentres,
	expenditure,
	ui,
});

export default rootReducer;