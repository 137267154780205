import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './EditCostCentre.styles';
import { useForm, Controller } from "react-hook-form";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';

import Typography from "@material-ui/core/Typography/Typography";

import { updateCostCentre } from '../../../actions/costCentreActions';
import { closeEditCostCentreModal } from '../../../actions/uiActions';

function EditCostCentreForm(props) {

  const { 
    classes, 
    costCentres,
    modalText,
    ui,
  } = props;

  let defaultValues = {
    Name: costCentres.activeCostCentre.Name,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, errors } = methods;

  const onSubmit = formData => {
    let formattedFormData = {
      Name: formData.Name,
      Code: costCentres.activeCostCentre.Code,
      CustomerSK: costCentres.activeCostCentre.CustomerSK,
      CostCentreKey: costCentres.activeCostCentre.CostCentreKey,
    }

    props.updateCostCentre(formattedFormData);
  }

  return (
    <Fragment>
      
      <Dialog
        open={ui.editCostCentreModalOpen}
        onClose={props.closeEditCostCentreModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <Typography variant="h2">{modalText}</Typography>
              </Grid>

              <Grid container item xs={12}>
                <Controller
                  as={<TextField error={!!errors.name} label="Cost Centre name" variant="outlined" />}
                  name="Name"
                  control={control}
                  rules={{ required: true }}
                  fullWidth={true}
                />
              </Grid>

              <Grid container item xs={12} direction="row"  justify="flex-end" className={classes.dialogButtons}>
                <Button onClick={props.closeEditCostCentreModal} color="primary" variant="outlined" className={classes.cancelButton}>
                  Cancel
                </Button>

                <Button type="submit" color="primary" variant="contained" size="large">Save</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  )
}

const mapDispatchToProps = {
  updateCostCentre,
  closeEditCostCentreModal,
};

const mapStateToProps = (state) => ({
  costCentres: state.costCentres,
  ui: state.ui,
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditCostCentreForm);
