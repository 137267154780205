import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";

import { getCustomers } from '../../../actions/customerActions';
import { 
  getCostCentres,
  getCostCentreDetailed,
  deleteThreshold,
  selectThreshold,
  deselectThreshold,
} from '../../../actions/costCentreActions';
import { 
  closeNotification,
  openAddCostCentreModal,
  closeAddCostCentreModal,
  openEditSubscriptionModal,
  openAddThresholdModal,
} from '../../../actions/uiActions';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ThresholdTabContent.styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Paper from '@material-ui/core/Paper';


import { LoadingSpinner }  from "../../LoadingSpinner";

import { updateSubscription, deleteSubscriptionRelation } from "../../../utility/api";

import EditSubscriptionForm from '../EditSubscription/EditSubscription';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ThresholdTabContent = (props) => {

  const [selectedCustomerSK, setSelectedCustomerSK] = useState(null);

  const [selectedThresold, setSelectedThreshold, selectedCostCentre] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [formResponseType, setFormResponseType] = useState(null);
  const [formResponse, setFormResponse] = useState(null);
  const [open, setOpen] = useState(false);

  const { 
    classes, 
    customers, 
    costCentres,
  } = props;

  const {
    currencies,
  } = costCentres;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => async (event, isExpanded, costCentreKey) => {
    setExpanded(isExpanded ? panel : false);

    if (isExpanded) {
      const activeCostCentre = costCentres.overview.find(costCentre => costCentre.CostCentreKey === panel);
      if (activeCostCentre.isDetailLoaded) {
        return;
      }

      props.getCostCentreDetailed(panel);
    }
  };

  const handleEditThreshold = () => {
    setAnchorEl(null);
    props.openAddThresholdModal({title: 'Edit Threshold'});
  }

  const handleDeleteThreshold = () => {
    setAnchorEl(null);
    props.deleteThreshold(selectedThresold);
  }

  const createNotification = (type, message) => {
    setFormResponseType(type);
    setFormResponse(message);
    setOpen(true);
  }

  const handleClick = (threshold) => (e) => {
    setAnchorEl(e.currentTarget);
    setSelectedThreshold(threshold);
    props.selectThreshold(threshold);
  };

  const getStripedStyle = (index) => {
    return { background: index % 2 ? '#F3F5F5' : 'none' };
  }

  const getAvailableOptions = (existingThresholds, allThresholds) => {
    let existingThresholdKeys = existingThresholds.reduce((a,c) => a.add(c.ThresholdKey), new Set());
    return allThresholds.filter(v => !existingThresholdKeys.has(v.ThresholdKey));
  }

  const getCurrencySymbol = (CurrencyKey) => {
    if (CurrencyKey) {
      return currencies.find(c => c.CurrencyKey === CurrencyKey).CurrencySymbol;
    }
    return null;
  }

  const getCurrencyName = (CurrencyKey) => {
    if (CurrencyKey) {
      return currencies.find(c => c.CurrencyKey === CurrencyKey).CurrencyName;
    }
    return null;
  }

  const userPermissionsIncludes = (CostCentreKey, role) => {
      for (let i = 0; i < props.account.permissions.length; i++) {
          if (CostCentreKey === props.account.permissions[i].costCentreKey &&
            role === props.account.permissions[i].RoleName ) {
                return true;
            }
      }

      return false;
  }

  return (
    <div>
      
      <Menu
          anchorEl={anchorEl}
          keepMounted
          open={openMenu}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleEditThreshold}>Edit Threshold</MenuItem>
          <MenuItem onClick={handleDeleteThreshold}>Delete Threshold</MenuItem>
        </Menu>

      {customers.data.map((row, index) => (
        <div key={row.CustomerSK}>
          <Typography className={index == 0 ? classes.customerNameSlim : classes.customerName} variant="h2">
            {row.CustomerName}
          </Typography>

          {costCentres.overview && 
           costCentres.overview
           .filter(costCentre => costCentre.CustomerSK === row.CustomerSK)
           .map(row => (

            <div className={classes.costCentreContainer} key={row.CostCentreKey}>
              <ExpansionPanel expanded={expanded === row.CostCentreKey} 
              onChange={handleChange(row.CostCentreKey)} 
              square
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                <Typography variant="h4" component="h3" className={classes.heading}>
                  {row.Name}
                </Typography>
                </ExpansionPanelSummary>

                {expanded && (

                <ExpansionPanelDetails className={classes.test}>

                  {costCentres.isLoadingDetailed && (
                    <div className={classes.spinnerContainer}>
                      <LoadingSpinner />
                    </div>
                  )}

                  {!costCentres.isLoadingDetailed && row.subscriptions &&

                  <TableContainer component={Paper} square elevation={0}>
                    <Table aria-label="Cost Centres">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{width: '40%'}} className={`${classes.bottomBorder} ${classes.PushLeft}`}>Threshold Name</TableCell>
                          <TableCell className={classes.bottomBorder}>Budget</TableCell>
                          <TableCell className={classes.bottomBorder}>Currency</TableCell>
                          <TableCell className={classes.bottomBorder}>Users</TableCell>
                          <TableCell className={classes.bottomBorder}></TableCell>
                        </TableRow>
                      </TableHead>

                      {row.isDetailLoaded && row.thresholds.map((threshold, index) => (
                        
                        <TableBody key={threshold.ThresholdKey}>
                          
                          <TableRow key={index} style={{ padding: '5px 20px', height: 25, ...getStripedStyle(index) }}>
                            <TableCell className={`${classes.removeBorder} ${classes.PushLeft}`}>
                              {threshold.ThresholdName}
                            </TableCell>

                            <TableCell className={classes.removeBorder}>
                              {getCurrencySymbol(threshold.CurrencyKey)}{threshold.ThresholdValue}
                            </TableCell>

                            <TableCell className={classes.removeBorder}>
                              {getCurrencyName(threshold.CurrencyKey)}
                            </TableCell>
                            
                            <TableCell className={classes.removeBorder}>
                              {threshold.users.length > 0 && (
                                <span>
                                  {threshold.users[0].firstname} {threshold.users[0].lastname}
                                  {threshold.users.length > 1 && (
                                    <span> + {threshold.users.length - 1}</span>
                                  )}
                                </span>
                              )}
                            </TableCell>

                            <TableCell align="right" className={classes.removeBorder}>
                                {console.log('props.account', props.account)}
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClick(threshold, threshold.ThresholdKey)}
                                className={(props.account.role != "Customer" || userPermissionsIncludes(row.costCentreKey, "Admin") ? classes.pullRight : classes.hide)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                      
                    </Table>
                </TableContainer>
                }
                   </ExpansionPanelDetails>

              )}

                </ExpansionPanel>
               </div>
              ))}
            </div>
          ))} 

    </div>
  );
}

const mapDispatchToProps = {
  getCustomers,
  getCostCentres,
  getCostCentreDetailed,
  closeNotification,
  openAddCostCentreModal,
  closeAddCostCentreModal,
  openEditSubscriptionModal,
  deleteThreshold,
  openAddThresholdModal,
  selectThreshold,
  deselectThreshold,
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  costCentres: state.costCentres,
  account: state.account,
  ui: state.ui,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(ThresholdTabContent);
