import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './SubscriptionLogo.styles';

import awsLogo from '../../../images/aws.svg';
import azureLogo from '../../../images/azure.svg';
import googleCloudLogo from '../../../images/google-cloud.svg';
import office365Logo from '../../../images/office-365.svg';

function SubscriptionLogo(props) {
  const { logoType, classes } = props;
  let logo;

  switch (logoType){
    case 'Azure':
      logo = azureLogo;
      break;
    case 'O365':
      logo = office365Logo;
      break;
    case 'AWS':
      logo = awsLogo;
      break;
    case 'Google':
      logo = googleCloudLogo;
      break;
    default:
      break;
  }

  return (
    <span>
      {logoType && (
        <img src={logo} className={classes.cloudLogo} alt="logo" />
      )}
     </span>
  )
}

export default compose(
  withStyles(styles),
)(SubscriptionLogo);
