export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

export const OPEN_ADD_COST_CENTRE_MODAL = 'OPEN_ADD_COST_CENTRE_MODAL';
export const CLOSE_ADD_COST_CENTRE_MODAL = 'CLOSE_ADD_COST_CENTRE_MODAL';

export const OPEN_EDIT_SUBSCRIPTION_MODAL = 'OPEN_EDIT_SUBSCRIPTION_MODAL';
export const CLOSE_EDIT_SUBSCRIPTION_MODAL = 'CLOSE_EDIT_SUBSCRIPTION_MODAL';

export const OPEN_ADD_THRESHOLD_MODAL = 'OPEN_ADD_THRESHOLD_MODAL';
export const CLOSE_ADD_THRESHOLD_MODAL = 'CLOSE_ADD_THRESHOLD_MODAL';

export const OPEN_DELETE_COST_CENTRE_CONFIRM_MODAL = 'OPEN_DELETE_COST_CENTRE_CONFIRM_MODAL';
export const CLOSE_DELETE_COST_CENTRE_CONFIRM_MODAL = 'CLOSE_DELETE_COST_CENTRE_CONFIRM_MODAL';

export const OPEN_EDIT_COST_CENTRE_MODAL = 'OPEN_EDIT_COST_CENTRE_MODAL';
export const CLOSE_EDIT_COST_CENTRE_MODAL = 'CLOSE_EDIT_COST_CENTRE_MODAL';

export const OPEN_EDIT_COST_CENTRE_USERS_MODAL = 'OPEN_EDIT_COST_CENTRE_USERS_MODAL';
export const CLOSE_EDIT_COST_CENTRE_USERS_MODAL = 'CLOSE_EDIT_COST_CENTRE_USERS_MODAL';
