import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login } from '../../actions/authActions';

const Login = (props) => {
  const { account, login, location } = props;
  console.log('cool beans');

  const params = queryString.parse(location.search);
  console.log(JSON.parse(params.token).jwt)
  login(JSON.parse(params.token).jwt);
  
  return account.isLoggedIn ? <Redirect to="/dashboard" /> : <Redirect to="/" />;
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = {
  login,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);