export const styles = ({ palette, spacingDefaults, typography }) => ({
  root: {
    width: '100%',
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: 0,
  },
  secondaryHeading: {
    fontSize: typography.pxToRem(15),
  },
  customerName: {
    marginTop: 45,
    marginBottom: 20,
    color: '#1D4E75',
  },
  customerNameSlim: {
    marginBottom: 20,
    color: '#1D4E75',
  },
  removePadding: {
    padding: 0,
  },
  costCentreContainer: {
    marginBottom: '25px',
  },
  removeBorder: {
    border: 0,
  },
  bottomBorder: {
    borderTop: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
    
  },
  PushLeft: {
    paddingLeft: '25px',
  },
  costCentreButton: {
    marginRight: spacingDefaults.double
  },
  paddedBox: {
    padding: '35px'
  },
  spinnerContainer: {
    flexGrow: 1,
  },
  pullRight: {
    marginLeft: 'auto',
    marginRight: '12px',
  },
  verticalCentre: {
    alignSelf: 'center',
  },
  hide: {
    visibility: 'hidden'
  }
});