import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { postUser, updateUser } from "../../../utility/api";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './NewUserForm.styles';
import { useForm, Controller } from "react-hook-form";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import Typography from "@material-ui/core/Typography/Typography";

function NewUserForm(props) {
  const { account, classes, customerList, userRoles, modalText, dialogOpen, closeDialog, editingUser, closeParentMenu, createNotification } = props;

  const handleCloseDialog = () => {
    if (closeParentMenu) {
      closeParentMenu();
    }

    closeDialog();
  };

  const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  let defaultValues = {
    firstname: '',
    lastname: '',
    email: '',
    CustomerSK: 0,
    RoleName: 'Customer',
  };

  if (editingUser){
    const {firstname, lastname, email, CustomerSK, RoleName} = editingUser;
    
    defaultValues = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      CustomerSK: CustomerSK,
      RoleName: RoleName,
    };
  }

  const methods = useForm({ defaultValues });
  const { handleSubmit, setValue, control, reset, errors } = methods;

  const onSubmit = formData => {

    const token = account.jwt;

    if(account.role !== 'JiscAdmin'){
      formData.CustomerSK = customerList[0].CustomerSK;
    }

    if (editingUser) {
      const {id} = editingUser;

      updateUser(formData, token, id).then(response => {
        switch (response.status) {
          case 200:
            createNotification('success', 'User updated successfully');
            props.addUser(formData);
            break;
          case 400:
            createNotification('error', response.data);
            break;
          default:
            createNotification('error', response.data);
            break;
        }
  
        handleCloseDialog();
      });
    } else {
      postUser(formData, token).then(response => {
        switch (response.status) {
          case 200:
            createNotification('success', 'User added successfully');
            props.addUser(response.data);
            break;
          case 400:
            createNotification('error', response.data);
            break;
          default:
            createNotification('error', response.data);
            break;
        }
  
        handleCloseDialog();
      });
    }
  }

  const checkEmailEditable = {
    ...( editingUser && { disabled: true } )
  };

  return (
    <Fragment>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <Typography variant="h2">{modalText}</Typography>
              </Grid>

              <Grid container item xs={6}>
                <Controller
                  as={<TextField error={!!errors.firstname} label="First name" variant="outlined" />}
                  name="firstname"
                  control={control}
                  rules={{ required: true }}
                  fullWidth={true}
                />
              </Grid>

              <Grid container item xs={6}>
                <Controller
                  as={<TextField error={!!errors.lastname} label="Last name" variant="outlined" />}
                  name="lastname"
                  control={control}
                  rules={{ required: true }}
                  fullWidth={true}
                />
              </Grid>

              <Grid container item xs={12}>
                <Controller
                  as={<TextField error={!!errors.email} label="Email address" variant="outlined" />}
                  name="email"
                  control={control}
                  rules={{ required: true, pattern: validEmailRegex }}
                  fullWidth={true}
                  {...checkEmailEditable}
                />
              </Grid>

              <Grid container item xs={6}>
                <Controller
                  as={
                    <Select variant="outlined">
                      {userRoles.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  name="RoleName"
                  control={control}
                  rules={{ required: true }}
                  fullWidth={true}
                />
              </Grid>

              
              <Grid container item xs={6}>
                {account.role === 'JiscAdmin' ? 
                  <Controller
                    as={
                      <Select variant="outlined" error={!!errors.CustomerSK} >
                        {customerList.map(option => (
                          <MenuItem key={option.CustomerSK} value={option.CustomerSK}>
                            {option.CustomerName}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    name="CustomerSK"
                    control={control}
                    rules={{ required: true, validate: value => value !== 0 }}
                    fullWidth={true}
                  /> : null
                }
              </Grid>

              <Grid container item xs={12} direction="row"  justify="flex-end" className={classes.dialogButtons}>
                <Button onClick={handleCloseDialog} color="primary" variant="outlined" className={classes.cancelButton}>
                  Cancel
                </Button>

                <Button type="submit" color="primary" variant="contained" size="large">Save</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
	account: state.account,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(NewUserForm);
