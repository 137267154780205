export const styles = ({ palette, spacingDefaults, typography }) => ({
  pdfIcon: {
    height: '22px',
  },
  removeBorder: {
    border: 0,
  },
  bottomBorder: {
    borderTop: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
  },
});