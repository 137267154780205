import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Register = (props) => {
  const { location } = props;

  const params = queryString.parse(location.search);
  const registrationId = params.token;
  
  localStorage.setItem('registrationId', registrationId);
  
  return <Redirect to="/" />;
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);