import {
  GET_COST_CENTRES, 
  GET_COST_CENTRES_SUCCESS, 
  GET_COST_CENTRES_FAILURE,

  GET_COST_CENTRE_DETAILED,
  GET_COST_CENTRE_DETAILED_SUCCESS,
  GET_COST_CENTRE_DETAILED_FAILURE,

  GET_COST_CENTRE_USERS,
  GET_COST_CENTRE_USERS_SUCCESS,
  GET_COST_CENTRE_USERS_FAILURE,
  
  CREATE_COST_CENTRE,
  CREATE_COST_CENTRE_SUCCESS,
  CREATE_COST_CENTRE_FAILURE,

  UPDATE_COST_CENTRE,
  UPDATE_COST_CENTRE_SUCCESS,
  UPDATE_COST_CENTRE_FAILURE,

  DELETE_COST_CENTRE,
  DELETE_COST_CENTRE_SUCCESS,
  DELETE_COST_CENTRE_FAILURE,

  UPDATE_THRESHOLD,
  UPDATE_THRESHOLD_SUCCESS,
  UPDATE_THRESHOLD_FAILURE,
  
  APPLY_THRESHOLD,
  APPLY_THRESHOLD_SUCCESS,
  APPLY_THRESHOLD_FAILURE,
  
  UNAPPLY_THRESHOLD,
  UNAPPLY_THRESHOLD_SUCCESS,
  UNAPPLY_THRESHOLD_FAILURE,

  MOVE_COST_CENTRE_SUBSCRIPION,
  MOVE_COST_CENTRE_SUBSCRIPION_SUCCESS,
  MOVE_COST_CENTRE_SUBSCRIPION_FAILURE,

  CREATE_THRESHOLD,
  CREATE_THRESHOLD_SUCCESS,
  CREATE_THRESHOLD_FAILURE,

  DELETE_THRESHOLD,
  DELETE_THRESHOLD_SUCCESS,
  DELETE_THRESHOLD_FAILURE,

  SELECT_THRESHOLD,
  DESELECT_THRESHOLD,

  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,

  SET_ACTIVE_COST_CENTRE,

  UPDATE_COST_CENTRE_PERMISSIONS,
  UPDATE_COST_CENTRE_PERMISSIONS_SUCCESS,
  UPDATE_COST_CENTRE_PERMISSIONS_FAILURE,
} from './costCentreActionTypes';
import { mb } from '../utility/mb';

export const getCostCentres = () => ({
  type: GET_COST_CENTRES,
});

export const getCostCentresSuccess = (data) => ({
  type: GET_COST_CENTRES_SUCCESS,
  payload: {
    data: data,
  },
});

export const getCostCentresFailure = error => ({
  type: GET_COST_CENTRES_FAILURE,
  payload: {
    message: mb(['response', 'data'])(error),
  },
});

export const getCostCentreDetailed = (data) => ({
  type: GET_COST_CENTRE_DETAILED,
  payload: {
    costCentreId: data,
  },
});

export const getCostCentreDetailedSuccess = (data) => ({
  type: GET_COST_CENTRE_DETAILED_SUCCESS,
  payload: {
    data: data,
  },
});

export const getCostCentreDetailedFailure = error => ({
  type: GET_COST_CENTRE_DETAILED_FAILURE,
  payload: {
    message: mb(['response', 'data'])(error),
  },
});

export const getCostCentreUsers = (data) => ({
  type: GET_COST_CENTRE_USERS,
  payload: {
    costCentreId: data,
  },
});

export const getCostCentreUsersSuccess = (data) => ({
  type: GET_COST_CENTRE_USERS_SUCCESS,
  payload: {
    data: data,
  },
});

export const getCostCentreUsersFailure = error => ({
  type: GET_COST_CENTRE_USERS_FAILURE,
  payload: {
    message: mb(['response', 'data'])(error),
  },
});

export const createCostCentre = (data) => ({
  type: CREATE_COST_CENTRE,
  payload: {
    data: data,
  },
});

export const createCostCentreSuccess = (data) => ({
  type: CREATE_COST_CENTRE_SUCCESS,
  payload: {
    data: data,
  },
});

export const createCostCentreFailure = error => ({
  type: CREATE_COST_CENTRE_FAILURE,
  payload: {
    data: error
  },
});

export const updateCostCentre = (data) => ({
  type: UPDATE_COST_CENTRE,
  payload: {
    data: data,
  },
});

export const updateCostCentreSuccess = (data) => ({
  type: UPDATE_COST_CENTRE_SUCCESS,
  payload: {
    data: data,
  },
});

export const updateCostCentreFailure = error => ({
  type: UPDATE_COST_CENTRE_FAILURE,
  payload: {
    data: error
  },
});

export const deleteCostCentre = (data) => ({
  type: DELETE_COST_CENTRE,
  payload: {
    data: data,
  },
});

export const deleteCostCentreSuccess = (data) => ({
  type: DELETE_COST_CENTRE_SUCCESS,
  payload: {
    data: data,
  },
});

export const deleteCostCentreFailure = error => ({
  type: DELETE_COST_CENTRE_FAILURE,
  payload: {
    data: error
  },
});

export const moveSubscription = (data) => ({
  type: MOVE_COST_CENTRE_SUBSCRIPION,
  payload: {
    data: data,
  },
})

export const moveSubscriptionSuccess = (data) => ({
  type: MOVE_COST_CENTRE_SUBSCRIPION_SUCCESS,
  payload: {
    data: data,
  },
})

export const moveSubscriptionFailure = error => ({
  type: MOVE_COST_CENTRE_SUBSCRIPION_FAILURE,
  payload: {
    data: error
  },
})

export const createThreshold = (data) => ({
  type: CREATE_THRESHOLD,
  payload: {
    data: data,
  },
});

export const createThresholdSuccess = (data) => ({
  type: CREATE_THRESHOLD_SUCCESS,
  payload: {
    data: data,
  },
});

export const createThresholdFailure = error => ({
  type: CREATE_THRESHOLD_FAILURE,
  payload: {
    data: error
  },
});

export const deleteThreshold = (data) => ({
  type: DELETE_THRESHOLD,
  payload: {
    data: data,
  },
});

export const deleteThresholdSuccess = (data) => ({
  type: DELETE_THRESHOLD_SUCCESS,
  payload: {
    data: data,
  },
});

export const deleteThresholdFailure = error => ({
  type: DELETE_THRESHOLD_FAILURE,
  payload: {
    data: error
  },
});

export const selectThreshold = (data) => ({
  type: SELECT_THRESHOLD,
  payload: {
    data: data,
  },
});

export const deselectThreshold = error => ({
  type: DESELECT_THRESHOLD,
});

export const updateThreshold = (data, id) => ({
  type: UPDATE_THRESHOLD,
  payload: {
    data: data,
  },
});

export const updateThresholdSuccess = (data) => ({
  type: UPDATE_THRESHOLD_SUCCESS,
  payload: {
    data: data,
  },
});

export const updateThresholdFailure = error => ({
  type: UPDATE_THRESHOLD_FAILURE,
  payload: {
    data: error
  },
});

export const applyThreshold = (data, costCentreKey, threshold) => ({
  type: APPLY_THRESHOLD,
  payload: {
    data: data,
    costCentreKey: costCentreKey,
    threshold: threshold
  },
});

export const applyThresholdSuccess = (data) => ({
  type: APPLY_THRESHOLD_SUCCESS,
  payload: {
    data: data,
  },
});

export const applyThresholdFailure = error => ({
  type: APPLY_THRESHOLD_FAILURE,
  payload: {
    data: error
  },
});

export const unapplyThreshold = (data, costCentreKey) => ({
  type: UNAPPLY_THRESHOLD,
  payload: {
    data: data,
    costCentreKey: costCentreKey
  },
});

export const unapplyThresholdSuccess = (data) => ({
  type: UNAPPLY_THRESHOLD_SUCCESS,
  payload: {
    data: data,
  },
});

export const unapplyThresholdFailure = error => ({
  type: UNAPPLY_THRESHOLD_FAILURE,
  payload: {
    data: error
  },
});

export const getCurrencies = (data) => ({
  type: GET_CURRENCIES,
});

export const getCurrenciesSuccess = (data) => ({
  type: GET_CURRENCIES_SUCCESS,
  payload: {
    data: data,
  },
});

export const getCurrenciesFailure = error => ({
  type: GET_CURRENCIES_FAILURE,
  payload: {
    message: error
  },
});

export const setActiveCostCentre = (data) => ({
  type: SET_ACTIVE_COST_CENTRE,
  payload: {
    data: data,
  }
});

export const updateCostCentrePermissions = (data) => ({
  type: UPDATE_COST_CENTRE_PERMISSIONS,
  payload: {
    data: data,
  },
});

export const updateCostCentrePermissionsSuccess = (data) => ({
  type: UPDATE_COST_CENTRE_PERMISSIONS_SUCCESS,
  payload: {
    data: data,
  },
});

export const updateCostCentrePermissionsFailure = error => ({
  type: UPDATE_COST_CENTRE_PERMISSIONS_FAILURE,
  payload: {
    data: error
  },
});
