import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
} from '../actions/customerActionTypes';

export const initialState = {
  isLoaded: false,
  isUsersLoaded: false,
  isLoading: false,
  isLoadingUsers: false,
  isLoadingFailed: false,
  data: [],
  users: [],
};

export const customers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    case GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isLoadingFailed: true,
      };
    case GET_USERS:
      return {
        ...state,
        isLoadingUsers: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        isLoadingUsers: false,
        isUsersLoaded: true,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        isLoadingUsers: false,
      };
    default:
      return state;
    }
};
