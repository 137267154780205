import {
  CREATE_NOTIFICATION,
  CLOSE_NOTIFICATION,
  OPEN_ADD_COST_CENTRE_MODAL,
  CLOSE_ADD_COST_CENTRE_MODAL,
  OPEN_EDIT_SUBSCRIPTION_MODAL,
  CLOSE_EDIT_SUBSCRIPTION_MODAL,
  OPEN_ADD_THRESHOLD_MODAL,
  CLOSE_ADD_THRESHOLD_MODAL,
  OPEN_DELETE_COST_CENTRE_CONFIRM_MODAL,
  CLOSE_DELETE_COST_CENTRE_CONFIRM_MODAL,
  OPEN_EDIT_COST_CENTRE_MODAL,
  CLOSE_EDIT_COST_CENTRE_MODAL,
  OPEN_EDIT_COST_CENTRE_USERS_MODAL,
  CLOSE_EDIT_COST_CENTRE_USERS_MODAL,
} from '../actions/uiActionTypes';

export const initialState = {
  dialogOpen: false,
  addCostCentreModalOpen: false,
  editSubscriptionModalOpen: false,
  addThresholdModalOpen: false,
  notificationActive: false,
  notificationMessage: '',
  notificationType: 'success',
  thresholdModalTitle: '',
  deleteCostCentreConfirmModalOpen: false,
  editCostCentreModalOpen: false,
  editCostCentreUsersModalOpen: false,
};

export const ui = (state = initialState, action) => {
  
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notificationActive: true,
        notificationMessage: action.payload.data.message,
        notificationType: action.payload.data.type,
      };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notificationActive: false,
      };
    case OPEN_ADD_COST_CENTRE_MODAL:
      return {
        ...state,
        addCostCentreModalOpen: true,
      };
    case CLOSE_ADD_COST_CENTRE_MODAL:
        return {
          ...state,
          addCostCentreModalOpen: false,
        };
    case OPEN_EDIT_SUBSCRIPTION_MODAL:
        return {
          ...state,
          editSubscriptionModalOpen: true,
        };
    case CLOSE_EDIT_SUBSCRIPTION_MODAL:
        return {
          ...state,
          editSubscriptionModalOpen: false,
        };
    case OPEN_ADD_THRESHOLD_MODAL:
        return {
          ...state,
          addThresholdModalOpen: true,
          thresholdModalTitle: action.payload.data.title,
        };
    case CLOSE_ADD_THRESHOLD_MODAL:
        return {
          ...state,
          addThresholdModalOpen: false,
        };
    case OPEN_DELETE_COST_CENTRE_CONFIRM_MODAL:
        return {
          ...state,
          deleteCostCentreConfirmModalOpen: true,
        };
    case CLOSE_DELETE_COST_CENTRE_CONFIRM_MODAL:
        return {
          ...state,
          deleteCostCentreConfirmModalOpen: false,
        };
    case OPEN_EDIT_COST_CENTRE_MODAL:
      return {
        ...state,
        editCostCentreModalOpen: true,
      };
    case CLOSE_EDIT_COST_CENTRE_MODAL:
      return {
        ...state,
        editCostCentreModalOpen: false,
      };
    case OPEN_EDIT_COST_CENTRE_USERS_MODAL:
      return {
        ...state,
        editCostCentreUsersModalOpen: true,
      };
    case CLOSE_EDIT_COST_CENTRE_USERS_MODAL:
      return {
        ...state,
        editCostCentreUsersModalOpen: false,
      };
    default:
        return state;
    };
    
};
