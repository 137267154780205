import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { styles } from './CloudCostPieChart.styles';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const COLORS = ['#6D43C2', '#37C4FA', '#5A8EF9'];
const RADIAN = Math.PI / 180;

const CloudCostPieChart = (props) => {

  const { costData } = props;
  const [costingSum, setCostingSum] = useState(0);

  useEffect(()  => {
    if (costData == '') {
      return;
    }
    const sum = costData.reduce((prev, cur) => prev + cur.Total, 0);
    setCostingSum(sum);
  }, [costData]);
  
  
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);

    let label = costData[index].DataSource || 'Unknown';

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'middle' : 'middle'} 	dominantBaseline="central" style={{ fontSize: '150%', fontWeight: 500 }}>
        {`${label} ${((value / costingSum) * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer height={360} width="100%">
      <PieChart>
        {costData &&
          <Pie
            data={costData}
            dataKey="Total"
            labelLine={false}
            label={renderCustomizedLabel}
            innerRadius={45}
            isAnimationActive={false}
          >
            {
              costData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length] || '#000'}/>)
            }
          </Pie>
        }
      </PieChart>
    </ResponsiveContainer>
  );
};

export default compose(
  withStyles(styles),
)(CloudCostPieChart);