export const styles = ({ palette, spacingDefaults, typography }) => ({
  cardTitle: {
    fontSize: typography.fontSizes.small,
    fontWeight: typography.fontWeightMedium,
  },
  cardSubHeader: {
    fontSize: typography.fontSizes.xsmall,
    fontWeight: typography.fontWeightMedium,
    color: '#545454'
  },
  threshold: {
    display: 'flex',
    flexDirection: 'column',
  },
  currentSpend: {
    fontSize: typography.fontSizes.small,
    fontWeight: typography.fontWeightMedium,
    lineHeight: 1.5,
  },
  thresholdLimit: {
    fontSize: typography.fontSizes.small,
    fontWeight: typography.fontWeightMedium,
    lineHeight: 1.5,
  },
  thresholdLimitHigh: {
    color: palette.thresholdGreen.main,
  },
  thresholdPercentage: {
    fontSize: typography.fontSizes.small,
    fontWeight: typography.fontWeightMedium,
  },
  thresholdPercentageHigh: {
    backgroundColor: palette.thresholdGreen.main,
  },
});