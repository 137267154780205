import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './AddCostCentre.styles';
import { useForm, Controller } from "react-hook-form";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';

import Typography from "@material-ui/core/Typography/Typography";

import { createCostCentre } from '../../../actions/costCentreActions';
import { closeAddCostCentreModal } from '../../../actions/uiActions';

function AddCostCentreForm(props) {

  const { 
    classes, 
    customers, 
    modalText,
    ui,
    account
  } = props;

  let defaultValues = {
    Name: '',
    Code: '',
    CustomerSK: 0,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, errors } = methods;

  const onSubmit = formData => {

    if(account.role !== 'JiscAdmin'){
      formData.CustomerSK = account.CustomerSK
    }

    props.createCostCentre(formData);
  }

  return (
    <Fragment>
      
      <Dialog
        open={ui.addCostCentreModalOpen}
        onClose={props.closeAddCostCentreModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <Typography variant="h2">{modalText}</Typography>
              </Grid>

              <Grid container item xs={6}>
                <Controller
                  as={<TextField error={!!errors.name} label="Cost Centre name" variant="outlined" />}
                  name="Name"
                  control={control}
                  rules={{ required: true }}
                  fullWidth={true}
                />
              </Grid>

              <Grid container item xs={6}>
                <Controller
                  as={<TextField error={!!errors.code} label="Cost Centre code" variant="outlined" />}
                  name="Code"
                  control={control}
                  rules={{ required: true }}
                  fullWidth={true}
                />
              </Grid>

              {account.role === 'JiscAdmin'? 
                <Grid container item xs={12}>
                  <InputLabel htmlFor="Customer">Customer</InputLabel>
                  <Controller
                    as={
                      <Select variant="outlined" id="Customer">
                        {customers.data.map(option => (
                          <MenuItem key={option.CustomerSK} value={option.CustomerSK}>
                            {option.CustomerName}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    name="CustomerSK"
                    control={control}
                    rules={{ required: true }}
                    fullWidth={true}
                  />
              </Grid>
              : null}

              <Grid container item xs={12} direction="row"  justify="flex-end" className={classes.dialogButtons}>
                <Button onClick={props.closeAddCostCentreModal} color="primary" variant="outlined" className={classes.cancelButton}>
                  Cancel
                </Button>

                <Button type="submit" color="primary" variant="contained" size="large">Save</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  )
}

const mapDispatchToProps = {
  createCostCentre,
  closeAddCostCentreModal,
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  ui: state.ui,
  account: state.account,
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddCostCentreForm);
