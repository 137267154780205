import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TitleBar from '../TitleBar/TitleBar';

const PrivacyPage = () => {

    return(
        <div>
            <TitleBar pageName='Cookies Statement' />
                <div>
                    <Typography variant="body1">The portal uses cookies for essential purposes to enable you to login and use the portal.</Typography>
                    <br />
                    <Typography variant="body1">We do not use any cookies to improve your experience using the site. This means we do not record which pages a user visits or how long they spend on each page.</Typography>
                    <br />
                    <Typography variant="body1">We do not use cookies to tailor the content you see based on your previous use of the portal.</Typography>
                    <br />
                    <Typography variant="h3">Essential cookies</Typography>
                    <br />
                    <Typography variant="h4">Website operation</Typography>
                    <br />
                    <Typography variant="body1">The system that runs our website sets cookies to allow it to run smoothly.</Typography>
                    <br />
                    <TableContainer component={Paper}>
                    <Table  aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell >Purprose</TableCell>
                            <TableCell >Expires</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>ARRAffinity</TableCell>
                                <TableCell>To ensure the user can move seamlessly between the portals' pages.</TableCell>
                                <TableCell>when you close your browser</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>jisc-cloud-billing</TableCell>
                                <TableCell>Used for user authentication</TableCell>
                                <TableCell>when you log out of the portal</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <br />
                    <Typography variant="h4">Logged in access</Typography>
                    <br />
                    <Typography variant="body1">Our system uses cookies to allow you to log into the site, and access restricted content and services.</Typography>
                    <br />
                    <TableContainer component={Paper}>
                    <Table  aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Purprose</TableCell>
                            <TableCell>Expires</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>_saml_idp</TableCell>
                                <TableCell>Allow our identity provider to recognise you</TableCell>
                                <TableCell>six months after last visiting the site</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>_shibsession_&lt;number&gt;</TableCell>
                                <TableCell>Allow the page to provide you with restricted content</TableCell>
                                <TableCell>when you close your browser</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <br />
                    <Typography variant="body1">Some users log onto the portal via the Open Athens IDP service. Open Athen’s statement is here <Link href='https://openathens.org/privacy/'>https://openathens.org/privacy/</Link></Typography>
                </div>
        </div>
    )
};



const mapDispatchToProps = {};
  
const mapStateToProps = (state) => ({});
  
export default compose(
    withRouter,
    // withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(PrivacyPage);