import { createMuiTheme } from '@material-ui/core/styles';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

const fontSizes = {
  xsmall: 12,
  small: 13,
  base: 18,
  medium: 18,
  large: 22,
  xlarge: 26,
  xxlarge: 28,
  xxxlarge: 32,
};

const colours = {
  error: '#F70005',
  primary: '#01567F',
  secondary: '#c3d1d6',
  tertiary: '#2D2D2D',
  success: '#49a027',
  warning: '#F79F00',
  defaultText: '#000000',
  secondaryText: '#007aaa',

  jiscOrange: '#e85e13',
  jiscGrey: '#2c3841',
  grey: '#646464',
};

const theme = createMuiTheme({
  mixins: {
    maxWidth: {
      maxWidth: 1000,
      margin: '0 auto',
      padding: [[0, 20]],
    },
    toolbar: {
      minHeight: 70,
    }
  },
  shape: {
    borderRadius: 5,
    borderWidth: 1,
  },
  typography: {
    fontSize: 20,
    useNextVariants: true,
    button: {
      textTransform: 'none',
      fontSize: fontSizes.xsmall,
      fontWeight: 500,
    },
    gutterBottom: {
      marginBottom: '30px'
    }
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: '#345875',
        width: 203,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 200,
      }
    },
    MuiCard: {
      root: {
        marginTop: '1px',
      }
    },
    MuiCardHeader: {
      subheader: {
        fontWeight: 'bold',
      },
      action: {
        marginTop: 0,
        marginRight: 0,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',

      },
      title: {
        fontSize: 19,
        fontWeight: 'bold',
      },
    },
    MuiChip: {
      root: {
        color: 'white',
        backgroundColor: '#01AB82',
        fontWeight: 700,
        fontSize: 11,
      },
      deleteIcon: {
        color: 'rgba(255, 255, 255, 0.8)',
        '&:hover:not($disabled)': {
          color: 'rgba(255, 255, 255, 1)',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#76767A',
        marginBottom: '8px',
        fontWeight: 500,
      }
    },
    MuiFormLabel: {
      root: {
        color: '#76767A',
        marginBottom: '8px',
        fontWeight: 500,
      }
    }
  },
});

theme.spacingDefaults = {
  unit: theme.spacing(0.625),
  half: theme.spacing(0.3125),
  double: theme.spacing(1.25),
  triple: theme.spacing(1.875),
  quad: theme.spacing(2.5),
};

theme.palette = {
  ...theme.palette,
  background: {
    ...theme.palette.background,
    'default': theme.palette.common.white,
  },
  text: {
    ...theme.palette.text,
    primary: colours.defaultText,
    secondary: colours.secondaryText,
  },
  error: {
    ...theme.palette.error,
    main: colours.error,
    contrastText: theme.palette.common.white,
  },
  primary: {
    ...theme.palette.primary,
    main: colours.primary,
    contrastText: theme.palette.common.white,
    dark: darken(colours.primary, 0.2),
    light: lighten(colours.primary, 0.2),
  },
  // secondary: {
  //   ...theme.palette.secondary,
  //   main: colours.secondary,
  //   contrastText: colours.secondaryText,
  //   dark: darken(colours.secondary, 0.2),
  //   light: lighten(colours.secondary, 0.2),
  // },
  tertiary: {
    ...theme.palette.tertiary,
    main: colours.tertiary,
    contrastText: theme.palette.common.white,
  },
  success: {
    ...theme.palette.success,
    main: colours.success,
  },
  warning: {
    ...theme.palette.warning,
    main: colours.warning,
    contrastText: colours.defaultText,
  },
  jiscOrange: {
    main: colours.jiscOrange,
    contrastText: theme.palette.common.white,
  },
  jiscGrey: {
    main: colours.jiscGrey,
    contrastText: theme.palette.common.white,
  },
  thresholdGreen: {
    main: '#22D273',
  },
};

theme.typography = {
  ...theme.typography,
  fontSizes,
  fontWeightRegular: 400,
  fontWeightBold: 700,
  fontFamily: 'Roboto, sans-serif',
  h1: {
    ...theme.typography.h1,
    color: colours.defaultText,
    fontSize: fontSizes.medium,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacingDefaults.double,
  },
  h2: {
    ...theme.typography.h2,
    color: colours.defaultText,
    fontSize: fontSizes.base,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacingDefaults.double,
  },
  h3: {
    ...theme.typography.h3,
    color: colours.defaultText,
    fontSize: fontSizes.base,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacingDefaults.double,
  },
  h4: {
    ...theme.typography.h4,
    color: colours.defaultText,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacingDefaults.double,
  },
  h5: {
    ...theme.typography.h4,
    color: colours.defaultText,
    fontSize: fontSizes.large,
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacingDefaults.triple,
  },
  h6: {
    ...theme.typography.h6,
    color: colours.defaultText,
    fontSize: fontSizes.small,
    fontStyle: "italic",
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacingDefaults.unit,
  },
  subtitle1: {
    ...theme.typography.subtitle1,
  },
  subtitle2: {
    ...theme.typography.subtitle2,
  },
  body1: {
    ...theme.typography.body1,
    color: colours.defaultText,
    fontSize: fontSizes.small,
    fontWeight: theme.typography.fontWeightRegular,
  },
  body2: {
    ...theme.typography.body1,
    color: colours.defaultText,
    fontSize: fontSizes.small,
    fontWeight: theme.typography.fontWeightRegular,
  },
  caption: {
    ...theme.typography.body1,
    color: colours.defaultText,
    fontSize: fontSizes.base,
    fontWeight: theme.typography.fontWeightMedium,
  },
};



export default theme;