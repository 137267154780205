export const styles = ({ palette, spacingDefaults, typography }) => ({
  autocomplete: {
    margin: '1rem 0'
  },
  fullWidth: {
    width: '100%'
  },
  welcomeBanner: {
    flexDirection: 'column',
    // background: 'linear-gradient(t o right, #2B4695, #7172FF)'
  },
  welcomeBannerText: {
    fontWeight: 500,
    fontSize: '24px',
    color: '#345875;'
  },
  card_title: {
    fontSize: '22px',
    fontWeight: 500,
    paddingLeft: '15px',
    flexDirection: "column",
    justifyContent: "center"
  },
  welcomeText: {
    marginTop: '14px',
    fontWeight: 300,
    fontSize: '18px',
    color: 'black'
  },
  featureListItem: {
    listStyleType: 'disc',
    width: '100%',
    fontSize: '16px',
    fontWeight: 600,
    margin: '0 0 20px 0',
  },
  paddedContent: {
    width: '100%',
  },
  homeFeatureImageContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  homeFeatureImage: {
    width: 'auto',
    alignSelf: 'center',
    maxWidth: '95%',
    maxHeight: '230px',
  },
  appLogo: {
    width: '60px'
  },
  introContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  introText: {
    color: palette.jiscOrange.main,
    fontSize: '20px',
    marginLeft: '15px',
    marginBottom: 0
  },
  moreInformation: {
    color: '#658093',
    fontSize: '12px',
    marginBottom: '35px'
  },
  paper: {
    padding: spacingDefaults.quad,
    display: 'flex',
  },
  pdfDownloadImage: {
    margin: '-20px',
    height: '125px',
    width: '125px',
    marginLeft: 'auto',
    alignSelf: 'flex-end',
  },
  featureTitle: {
    color: '#007C9E',
    fontSize: '20px',
    marginBottom: '25px'
  },
  featureTitleGreen: {
    color: '#B2BB1C'
  },
  featureTitleBlack: {
    color: 'black'
  },
  featureTitleJisc: {
    color: palette.jiscOrange.main
  },
  featureTitleDarkGreen: {
    color: '#27793C'
  },
  featureInfo: {
    width: '55%',
    fontSize: '12px',
    color: '#50697D'
  },
  featureDownloadContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    height: '100%',
    width: '100%'
  },
  featureDownloadButton: {
    alignSelf: 'flex-end'
  },
  paperFeedback: {
    backgroundColor: '#D1F6E3',
  },
});