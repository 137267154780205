export const styles = () => ({
    top: {
        color: 'white',
        fontWeight: '500',
        textAlign: 'center',
        padding: '10px 0'
    },
    white: {
        color: 'white',
        margin: 0
    },
    container: {
        padding: '30px 0',
        textAlign: 'center'
    },
    containerText: {
        margin: 0,
        fontSize: '26px'
    },
    bottom: {
        height: '10px',
        
    }
});