import React, { useState, useEffect, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import { styles } from './AddThreshold.styles';

import { useForm, Controller } from "react-hook-form";

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography/Typography";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';

import { LoadingSpinner }  from "../../LoadingSpinner";

import { 
  getCostCentreUsers, 
  createThreshold, 
  deselectThreshold, 
  updateThreshold 
} from '../../../actions/costCentreActions';
import { closeAddThresholdModal } from '../../../actions/uiActions';

function AddThresholdForm(props) {
  const { 
    classes,
    customers,
    costCentres,
    account
  } = props;

  const {
    selectedThreshold,
    activeCostCentre,
    currencies,
  } = costCentres;

  let defaultValues = {
    Name: '',
    Value: '',
    CurrencyKey: "1",
    CostCentreKey: '',
    CustomerSK: '',
  };

  const [users, setUsers] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);

  if (selectedThreshold){
    const {
      ThresholdName,
      ThresholdValue,
      CurrencyKey,
      CostCentreKey,
    } = selectedThreshold;

    defaultValues = {
      Name: ThresholdName,
      Value: ThresholdValue,
      CurrencyKey: `${CurrencyKey}`,
      CostCentreKey: CostCentreKey,
      CustomerSK: activeCostCentre.CustomerSK,
    };
  }

  useEffect(()  => {
    if (selectedThreshold){
      setUsers(selectedThreshold.users);
      setInitialUsers(selectedThreshold.users);
      props.getCostCentreUsers(selectedThreshold.CostCentreKey);
    }
  }, [selectedThreshold]);

  const methods = useForm({ defaultValues });
  const { handleSubmit, watch, control, errors } = methods;
  
  const selectedCustomerSK = account.role === 'JiscAdmin' ? watch('CustomerSK') : account.CustomerSK;
  const selectedCostCentreKey = watch('CostCentreKey'); 

  const CurrencyKey = watch('CurrencyKey'); 

  const onSubmit = formData => {

    console.log({formData})

    if (selectedThreshold) {
      
      const initialUsersIds = initialUsers.map(o => o.id);
      const userIds = users.map(o => o.id);

      let addedUsers = users.filter((user) => !initialUsersIds.includes(user.id));
      let removedUsers = initialUsers.filter((user) => !userIds.includes(user.id));

      let formDataWithUsers = {
        ...formData,
        ThresholdKey: selectedThreshold.ThresholdKey,
        users: users,
        usersToAdd: addedUsers,
        usersToRemove: removedUsers,
      }
      props.updateThreshold(formDataWithUsers);
      props.getCostCentreUsers(selectedThreshold.CostCentreKey);
    } else {
      let formDataWithUsers = {
        ...formData,
        users,
      }
      props.createThreshold(formDataWithUsers);
    }
  }

  const loadUsers = event => {
    props.getCostCentreUsers(event.target.value);
  }

  const handleCloseModal = () => {
    props.deselectThreshold();
    props.closeAddThresholdModal();
  }

  const filterSelectedUsers = (allCostCentreUsers) => {
    const userIds = users.map(o => o.id);
    const filteredUser = allCostCentreUsers.filter((user) => userIds.includes(user.id));

    return filteredUser;
  }

  return (
    <Fragment>
      <Dialog
        open={props.ui.addThresholdModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <Typography variant="h2">{props.ui.thresholdModalTitle}</Typography>
              </Grid>

              <Grid container item xs={6}>
                <Controller
                  as={<TextField error={!!errors.name} label="Threshold name" variant="outlined" />}
                  name="Name"
                  control={control}
                  rules={{ required: true }}
                  fullWidth={true}
                />
              </Grid>

              <Grid container item xs={6}>
                <Controller
                  as={<TextField error={!!errors.code} label="Threshold value" variant="outlined" type="number" />}
                  name="Value"
                  control={control}
                  rules={{ required: true }}
                  fullWidth={true}
                />
              </Grid>
              {account.role === 'JiscAdmin' ?
                <Grid container item xs={12} className={selectedThreshold ? classes.hide : null}>
                  <InputLabel htmlFor="Customer">Customer</InputLabel>
                  <Controller
                    as={
                      <Select variant="outlined" label="Customer" id="Customer">
                        {customers.data.map(customer => (
                          <MenuItem value={customer.CustomerSK} key={customer.CustomerSK}>
                            {customer.CustomerName}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    name="CustomerSK"
                    control={control}
                    rules={{ required: true }}
                    fullWidth={true}
                  />
                </Grid>
              : null}
                
              {selectedCustomerSK != '' && (
                <Grid container item xs={12} className={selectedThreshold ? classes.hide : null}>
                  
                  <InputLabel htmlFor="CostCentre">Cost Centre</InputLabel>
                  <Controller
                    as={
                      <Select variant="outlined" name="CostCentreKey" label="Cost Centre" id="CostCentre">
                        {costCentres.overview
                          .filter(costCentre => costCentre.CustomerSK === selectedCustomerSK)
                          .map(costCentre => (
                          <MenuItem key={costCentre.CostCentreKey} value={costCentre.CostCentreKey}>
                            {costCentre.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    name="CostCentreKey"
                    control={control}
                    rules={{ required: true }}
                    fullWidth={true}
                    onChange={([selected]) => {
                      loadUsers(selected);
                      return { value: selected.target.value };
                    }}
                  /> 
                  
                </Grid>
              )}

              <Grid container item xs={12}>
                <Controller
                  as={
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Currency</FormLabel>
                      <RadioGroup aria-label="currency" name="CurrencyKey" row defaultValue={CurrencyKey}>
                        {currencies
                        .filter(option => option.CurrencyName !== 'Unknown')
                        .map(option => (
                          <FormControlLabel
                            value={`${option.CurrencyKey}`}
                            control={<Radio color="primary" />}
                            label={option.CurrencyName}
                            labelPlacement="end"
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  }
                  name="CurrencyKey"
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>

             

              {costCentres.overview
                .filter(costCentre => costCentre.CostCentreKey === selectedCostCentreKey)
                .map(costCentre => (
                  <Grid container item xs={12}>

                    {costCentre.isUsersLoaded && (
                      <Autocomplete
                        classes={{
                          root: classes.muiAutoCompleteRoot
                        }}
                        disableClearable
                        multiple
                        options={costCentre.users}
                        defaultValue={ filterSelectedUsers(costCentre.users) }
                        getOptionLabel={option => `${option.firstname} ${option.lastname}`}
                        filterSelectedOptions
                        onChange={(event, newValue) => {
                          setUsers(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Users"
                            placeholder="Add User"
                            fullWidth
                          />
                        )}
                        />
                    )}

                    {!costCentre.isUsersLoaded && (
                      <LoadingSpinner />
                    )}
                  </Grid>
              ))}

              <Grid container item xs={12} direction="row"  justify="flex-end" className={classes.dialogButtons}>
                <Button onClick={handleCloseModal} color="primary" variant="outlined" className={classes.cancelButton}>
                  Cancel
                </Button>

                <Button type="submit" color="primary" variant="contained" size="large">Save</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  )
}

const mapDispatchToProps = {
  getCostCentreUsers,
  createThreshold,
  updateThreshold,
  closeAddThresholdModal,
  deselectThreshold,
  updateThreshold,
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  costCentres: state.costCentres,
  ui: state.ui,
  account: state.account,
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(AddThresholdForm);
