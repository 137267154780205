import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ThresholdCard.styles';
import { withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';

const ThresholdCard = (props) => {
  const { classes, name, costCentre, currentSpend, thresholdLimit } = props;

  return (
  <Card className={classes.card} square>
    <CardHeader
      classes={{
        title: classes.cardTitle,
        subheader: classes.cardSubHeader
      }}
      avatar={
        <Avatar aria-label="percentage" className={`${classes.thresholdPercentage} ${classes.thresholdPercentageHigh}`}>
          97%
        </Avatar>
      }
      action={
        <div className={classes.threshold}>
          <span className={classes.currentSpend}>£{currentSpend}</span>
          <span className={`${classes.thresholdLimit} ${classes.thresholdLimitHigh}`}>£{thresholdLimit}</span>
        </div>
      }
      title={name}
      subheader={costCentre}
    />
  </Card>
  );
};

export default compose(
  withRouter,
	withStyles(styles)
)(ThresholdCard);
