import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './AccountHeader.styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { 
  getEntities
} from './../../utility/api';
const ssoAppId = process.env.REACT_APP_AUTH_APPID;

const AccountHeader = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const {isLoggedIn, userName, classes} = props;
    const [ entites, setEntities ] = useState([]);
    const [ entity, setEntity ] = useState(null);

    useEffect(() => {
      getEntities().then(data => setEntities(data))
    }, []);


    useEffect(() => {

      if(entity){
        const returnUrl = '/login';
        const url = `https://jwtsp.jisc.ac.uk/Shibboleth.sso/Login?entityID=${entity}&target=https://jwtsp.jisc.ac.uk/secure/auth-webv2.php?returl=${window.location.origin}${returnUrl}%26appid=${ssoAppId}`
        console.log(url);
        window.location = url;
      }

    }, [entity])

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick2 = event => {
      setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
      setAnchorEl2(null);
    };

    const handleLogout = () => {
      props.logout();
      handleClose();
    };

    const handleLogin = () => {
      props.login();
      handleClose();
    }

    const handleLink = (routeRef) => {
      props.history.push(routeRef);
      handleClose();
    }

    return (
      <div>
        {isLoggedIn
          &&  
          <React.Fragment>
            <Menu
              id="account-menu"
              anchorEl={anchorEl2}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose2}
            >
              <MenuItem onClick={handleLogout} classes={{root: classes.menuItem}}>Logout</MenuItem>
            </Menu>
            <Button aria-controls="account-menu" aria-haspopup="true" onClick={handleClick}>
              <AccountCircleIcon className={classes.accountIcon} />
              {userName}
              <ExpandMoreIcon className={classes.arrowDown}/>
            </Button>
            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout} classes={{root: classes.menuItem}}>Logout</MenuItem>
            </Menu>
          </React.Fragment>
        }
      </div>
    );
}

export default compose(
  withRouter,
	withStyles(styles)
)(AccountHeader);
