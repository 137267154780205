import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";

import { getCustomers } from '../../../actions/customerActions';
import { 
  getCostCentres,
  getCostCentreDetailed,
  applyThreshold,
  unapplyThreshold,
  deleteCostCentre,
  setActiveCostCentre,
} from '../../../actions/costCentreActions';
import { 
  closeNotification,
  openAddCostCentreModal,
  closeAddCostCentreModal,
  openEditSubscriptionModal,
  openDeleteCostCentreConfirmModal,
  closeDeleteCostCentreConfirmModal,
  openEditCostCentreModal,
  openEditCostCentreUsersModal,
} from '../../../actions/uiActions';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './CostCentreTabContent.styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Paper from '@material-ui/core/Paper';

import { LoadingSpinner }  from "../../LoadingSpinner";

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import EditSubscriptionForm from '../EditSubscription/EditSubscription';
import SubscriptionLogo from '../SubscriptionLogo/SubscriptionLogo';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const CostCentreTabContent = (props) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedCustomerSK, setSelectedCustomerSK] = useState(null);

  const [selectedCostCentre, setSelectedCostCentre] = useState(null);

  const [thresholdDialogOpen, setThresholdDialogOpen] = useState(false);
  const handleThresholdDialogOpen = () => {
    setThresholdDialogOpen(true);
  }
  const handleCloseThreshold = () => {
    setThresholdDialogOpen(false);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const [anchorElCostCentre, setAnchorElCostCentre] = React.useState(null);
  const openMenuCostCentre = Boolean(anchorElCostCentre);
  const handleCloseMenuCostCentre = () => {
    setAnchorElCostCentre(null);
  };

  const [formResponseType, setFormResponseType] = useState(null);
  const [formResponse, setFormResponse] = useState(null);
  const [open, setOpen] = useState(false);

  const { 
    classes, 
    customers, 
    costCentres,
    ui,
  } = props;

  const {
    activeCostCentre
  } = costCentres;


  const token = props.account.jwt;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => async (event, isExpanded, costCentreKey) => {
    setExpanded(isExpanded ? panel : false);

    if (isExpanded) {
      loadCostCentreDetail(panel);
    }
  };

  const loadCostCentreDetail = (costCentreKey) => {
    let activeCostCentre = costCentres.overview.find(costCentre => costCentre.CostCentreKey === costCentreKey);

    props.setActiveCostCentre(activeCostCentre);

    if (activeCostCentre && activeCostCentre.isDetailLoaded) {
      return;
    }
    
    props.getCostCentreDetailed(costCentreKey);
  }

  const createNotification = (type, message) => {
    setFormResponseType(type);
    setFormResponse(message);
    setOpen(true);
  }

  const handleClick = (subscription, customerSK) => (e) => {
    setSelectedSubscription(subscription);
    setSelectedCustomerSK(customerSK);
    setAnchorEl(e.currentTarget);
  };

  const getStripedStyle = (index) => {
    return { background: index % 2 ? '#F3F5F5' : 'none' };
  }

  const handleOpenSubscriptionMenu = () => {
    handleCloseMenu();
    props.openEditSubscriptionModal();
  }

  const handleCostCentreMoreButton = (event, costCentre) => {
    event.stopPropagation();
    setAnchorElCostCentre(event.currentTarget);
    setSelectedCostCentre(costCentre);
    loadCostCentreDetail(costCentre.CostCentreKey);
  }

  const handleThresholdTagsChange = (event, values, costCentreKey, subscriptionSK) => {

    let updatedCostCentre = costCentres.overview.find(costCentre => costCentre.CostCentreKey === costCentreKey);

    let updatedSub = updatedCostCentre.subscriptions.find(sub => sub.SubscriptionSK === subscriptionSK);

    const currentThresholdRelations = updatedSub.thresholds.length;

    if (values.length >= currentThresholdRelations) {

      const thresholdKey = values[values.length - 1].ThresholdKey;

      let formattedThresholdData = {
        SubscriptionKey: subscriptionSK,
        ThresholdKey: thresholdKey,
      }

      props.applyThreshold(formattedThresholdData, costCentreKey, values[values.length - 1]);

    } else {
      let nonSelectedItems = updatedSub.thresholds.find(obj => values.every(s => s.ThresholdKey !== obj.ThresholdKey));

      let formattedThresholdData = {
        SubscriptionKey: subscriptionSK,
        ThresholdKey: nonSelectedItems.ThresholdKey,
      }

      props.unapplyThreshold(formattedThresholdData, costCentreKey);

    }
  }

  const filterSelectedUsers = (selected, allThresholds) => {
    const userIds = selected.map(o => o.ThresholdKey);
    const filteredUser = allThresholds.filter((user) => userIds.includes(user.ThresholdKey));

    return filteredUser;
  }

  const handleEditCostCentre = () => {
    props.openEditCostCentreModal();
    setAnchorElCostCentre(null);
  }

  const handleDeleteCostCentre = () => {
    props.openDeleteCostCentreConfirmModal();
    setAnchorElCostCentre(null);
  }

  const handleDeleteCostCentreConfirmClose = () => {
    props.closeDeleteCostCentreConfirmModal();
  }

  const handleEditCostCentreUsers = (event, costCentreKey) => {
    event.stopPropagation();

    let activeCostCentre = costCentres.overview.find(costCentre => costCentre.CostCentreKey === costCentreKey);
    props.setActiveCostCentre(activeCostCentre);

    props.openEditCostCentreUsersModal();
  }
  
  const userPermissionsIncludes = (CostCentreKey, role) => {
    for (let i = 0; i < props.account.permissions.length; i++) {
        const permission = props.account.permissions[i]
        if (CostCentreKey === permission.CostCentreKey &&
          role === permission.PermissionLevel ) {
              return true;
          }
    }

    return false;
}

  const DeleteModal = () => (
    <Dialog
      open={ui.deleteCostCentreConfirmModalOpen}
      onClose={handleDeleteCostCentreConfirmClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this Cost Centre and all of the related thresholds?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteCostCentreConfirmClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => props.deleteCostCentre(selectedCostCentre.CostCentreKey)} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <div>

      <DeleteModal/>
      
      <Menu
          anchorEl={anchorEl}
          keepMounted
          open={openMenu}
          onClose={handleCloseMenu}
        >
        <MenuItem onClick={handleOpenSubscriptionMenu}>Move Subscription</MenuItem>
      </Menu>

      <Menu
          anchorEl={anchorElCostCentre}
          keepMounted
          open={openMenuCostCentre}
          onClose={handleCloseMenuCostCentre}
        >

        <MenuItem onClick={handleEditCostCentre}>Edit Cost Centre</MenuItem>
      
        {selectedCostCentre &&
         !costCentres.isLoadingDetailed && 
         !selectedCostCentre.DefaultCostCentre &&
         selectedCostCentre.subscriptions &&
         selectedCostCentre.subscriptions.length == 0 &&
          <MenuItem onClick={handleDeleteCostCentre}>Delete Cost Centre</MenuItem>
        }
      </Menu>

        {selectedSubscription && (

          <EditSubscriptionForm 
            createNotification={createNotification} 
            modalText={'Move Subscription'}
            editingUser={selectedSubscription}
          />

        )}

      {customers.data.map((row, index) => (
        <div key={row.CustomerSK}>
          <Typography className={index == 0 ? classes.customerNameSlim : classes.customerName} variant="h2">
            {row.CustomerName}
          </Typography>

          {costCentres.overview && 
           costCentres.overview
           .filter(costCentre => costCentre.CustomerSK === row.CustomerSK)
           .map(row => (

            <div className={classes.costCentreContainer} key={row.CostCentreKey}>
              
              <ExpansionPanel
                expanded={expanded === row.CostCentreKey} 
                TransitionProps={{ unmountOnExit: true }}
                onChange={handleChange(row.CostCentreKey)} 
                square
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography variant="h4" component="h3" className={`${classes.heading} ${classes.verticalCentre}`}>
                    {row.Name}
                  </Typography>

                  <Button
                    onClick={event => handleEditCostCentreUsers(event, row.CostCentreKey)}
                    onFocus={(event) => event.stopPropagation()}
                    color="primary"
                    variant="outlined"
                    className={(props.account.role != "Customer" || userPermissionsIncludes(row.CostCentreKey, "Admin") ? classes.pullRight : classes.hide)}
                    >
                    Users
                  </Button>

                  <IconButton
                      aria-label="Cost Centre Actions"
                      aria-haspopup="true"
                      onClick={event => handleCostCentreMoreButton(event, row)}
                      onFocus={(event) => event.stopPropagation()}
                      className={(props.account.role != "Customer" || userPermissionsIncludes(row.CostCentreKey, "Admin") ? '' : classes.hide)}
                    >
                    <MoreVertIcon />
                  </IconButton>

                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.removePadding}>

                  {costCentres.isLoadingDetailed && (
                    <div className={classes.spinnerContainer}>
                      <LoadingSpinner />
                    </div>
                  )}

                  {!costCentres.isLoadingDetailed && row.subscriptions &&

                  <TableContainer component={Paper} square elevation={0}>
                    <Table aria-label="Cost Centres">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{width: '40%'}} className={`${classes.bottomBorder} ${classes.PushLeft}`}>Subscription</TableCell>
                          <TableCell className={classes.bottomBorder}>Budget Threshold</TableCell>
                          <TableCell className={classes.bottomBorder}></TableCell>
                        </TableRow>
                      </TableHead>

                      {row.isDetailLoaded && row.subscriptions.map((sub, index) => (
                        
                        

                        <TableBody key={sub.SubscriptionSK}>
                          {console.log({sub})}  
                          <TableRow key={index} style={{ padding: '5px 20px', height: 25, ...getStripedStyle(index) }}>
                            <TableCell className={`${classes.removeBorder} ${classes.PushLeft}`}>
                              <SubscriptionLogo logoType={sub.SubscriptionPresentationType} />
                              {sub.Description}
                            </TableCell>

                            <TableCell className={classes.removeBorder}>
                                {console.log('row.thresholds', row.thresholds)}
                                {sub.thresholds && sub.SubscriptionPresentationType !== 'O365' && (
                                  <Autocomplete
                                    disableClearable
                                    multiple
                                    id={`tags-outlined${sub.SubscriptionSK}`}

                                    options={row.thresholds}

                                    getOptionLabel={option => `${option.ThresholdName} ${option.CurrencyKey === 1 ? '£' : '$'}${option.ThresholdValue}`}
                                    
                                    defaultValue={ filterSelectedUsers(sub.thresholds, row.thresholds) }
                                    filterSelectedOptions

                                    onChange={(event, value) => { handleThresholdTagsChange(event, value, row.CostCentreKey, sub.SubscriptionSK)} }
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Thresholds"
                                        placeholder="Add Threshold"
                                        fullWidth
                                      />
                                    )}
                                  />
                                )}

                                {sub.SubscriptionPresentationType === 'O365' && (
                                  <Typography>
                                    Unable to add thresholds to Office 365 subscriptions
                                  </Typography>
                                )}

                            </TableCell>

                            <TableCell align="right" className={classes.removeBorder}>
                          
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClick(sub, row.CustomerSK)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                      
                    </Table>
                </TableContainer>
                }
                   </ExpansionPanelDetails>
                </ExpansionPanel>
               </div>
              ))}
            </div>
          ))} 

    </div>
  );
}

const mapDispatchToProps = {
  getCustomers,
  getCostCentres,
  getCostCentreDetailed,
  closeNotification,
  openAddCostCentreModal,
  closeAddCostCentreModal,
  openEditSubscriptionModal,
  applyThreshold,
  unapplyThreshold,
  deleteCostCentre,
  openDeleteCostCentreConfirmModal,
  closeDeleteCostCentreConfirmModal,
  openEditCostCentreModal,
  setActiveCostCentre,
  openEditCostCentreUsersModal,
};

const mapStateToProps = (state) => ({
  account: state.account,
  customers: state.customers,
  costCentres: state.costCentres,
  ui: state.ui,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(CostCentreTabContent);
