/*
 * utility for Exception-free nested nullable attribute access as per
 * https://github.com/burakcan/mb
 */

// let's not check known working library code again
// eslint-disable-next-line
export const mb=p=>o=>p.map(c=>o=(o||{})[c])&&o

export const isObject = (o) => o instanceof Object;
