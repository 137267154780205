import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getExpenditure, getExpenditureFailure, getExpenditureSuccess } from '../actions/expenditureAction';
import { GET_EXPENDITURE } from '../actions/expenditureActionTypes';

import { getCurrentMonthExpenditure, getPreviousMonthExpenditure, getRemainingCredits } from '../utility/api';

export default function *customerSaga () {
  console.log('COOLd');
  yield takeEvery(GET_EXPENDITURE, getMonthlyExpenditure);
}

function *getMonthlyExpenditure (action) {
  console.log('loottss')
  try {
      const token = yield select(state => state.account.jwt);

      const currentMonthData = yield call(getCurrentMonthExpenditure, token);
      const previousMonthData = yield call(getPreviousMonthExpenditure, token);
      const credits = yield call(getRemainingCredits, token);


      const data = {current_month: currentMonthData, previous_month: previousMonthData, credits};

      console.log('data', data);

      yield put(getExpenditureSuccess(data));

    } catch (error) {
      console.log('error', error)
      yield put(getExpenditureFailure({
        message: 'Could not retrieve'
      }));
    }
}