import { call, put, takeEvery } from 'redux-saga/effects';

import { getUserProfileSuccess, getUserProfileFailure } from '../actions/authActions';
import { LOGIN } from '../actions/authActionTypes';
import { getUserDetails, getUserToken } from "../utility/api";

import { mb } from "../utility/mb";

import { loginStatus } from "../config/status";

const UNAUTHORISED_USER_VALUE = 'Unauthorised user';

export default function *accountSaga () {
  yield takeEvery(LOGIN, getUserProfile);
}

const getUserInfo = (EPPN, token) => {
  return getUserDetails(EPPN, token)
    .then((res) => {
      return [mb(['EPPN'])(res), mb(['Username'])(res)];
    });
};

const authoriseUser = async (userInfo) => {
  return await userInfo[1] !== UNAUTHORISED_USER_VALUE;
};

const getUserName = (userInfo) => {
  return userInfo[1];
};

function *getUserProfile (action) {
  const { token } = action.payload;
  try {
    yield put(getUserProfileSuccess( yield call(getUserToken, token) ));
  } catch (error) {
    console.error(error);
    yield put(getUserProfileFailure(loginStatus.UNKNOWN_ERROR));
  }
}
