export const styles = ({ mixins, palette, spacingDefaults, MuiDrawer, overrides }) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: overrides.MuiDrawer.paper.width,
    flexShrink: 0,
  },
  drawerPaper: {
    color: '#fff',
    borderRight: 0,
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    ...mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: '#fff',
  },
  appLogo: {
    height: 30,
    width: 30,
    marginLeft: spacingDefaults.triple,
    marginRight: spacingDefaults.triple,
  },
  title: {
    flexGrow: 1,
    color: '#fff',
    margin: 0,
    fontSize: 12,
  },
  menu: {
    paddingLeft: spacingDefaults.triple,
    marginTop: 30,
  },
  settingsItem: {
    marginTop: 40,
  },
  menuListItem: {
    color: 'white',
    minWidth: '40px',
  },
  menuListItemText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
  },
  icon: {
    height: 25,
    width: 25,
  },
  listItemButton: {
    borderRadius: '5px 0 0 5px',
    '&:selected=true': {
      backgroundColor: '#fff',
    },
  },
  listItemButtonActive: {
    backgroundColor: '#fff',
    color: 'black',
    pointerEvents: 'none',
    '& span, & div': {
      color: "#345977"
    },    
    '&:hover, &:focus': {
      backgroundColor: '#fff',
    },
  },
});
