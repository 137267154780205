import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, Label, LineChart, Line, XAxis, YAxis } from 'recharts';
import { styles } from './OverallSpendLineChart.styles';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { getDashboardChartSpend } from "../../../utility/api";
import moment from 'moment';
import { LoadingSpinner }  from "../../LoadingSpinner";
import Typography from "@material-ui/core/Typography/Typography";

const OverallSpendLineChart = (props) => {
  
  const [dataLoaded, setDataLoaded] = useState(false);
  const [chartData, setChartData] = useState(null);
  const token = props.account.jwt;

  const { selectedCustomer } = props;

  useEffect(()  => {
    if (selectedCustomer == '') {
      return;
    }

    setDataLoaded(false);
    setChartData(null);

    getDashboardChartSpend(token, selectedCustomer)
      .then(data => {
        let formattedData = formatData(data);
        setChartData(formattedData);
        setDataLoaded(true);
      })
      .catch(error => {
        setDataLoaded(true);
      })

  }, [selectedCustomer]);

  const formatData = (chartData) => {
    let result = chartData.map(data => {
      data.Date = moment(data.Date).format('MMMM');
      return data;
    });

    return result;
  }

  if (!dataLoaded) {
    return (
      <LoadingSpinner/>
    )
  }

  if (dataLoaded && !chartData) {
    return (
      <Typography variant="body1">No data to display</Typography>
    )
  }

  return (
    <ResponsiveContainer height={360}>
      <LineChart data={chartData} margin={{
          top: 16,
          right: 16,
          bottom: 0,
          left: 24,
        }}>
        <XAxis dataKey="Date"/>
        <YAxis>
          <Label
            angle={70}
            position="left"
            style={{ textAnchor: 'middle' }}
          >
            Cost (£)
          </Label>
        </YAxis>
        <Line type="monotone" dataKey="Total" stroke="#00CCCC" dot={false} strokeWidth={2}/>
      </LineChart>
    </ResponsiveContainer>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  customers: state.customers,
  costCentres: state.costCentres,
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
  )
)(OverallSpendLineChart);