export const styles = ({ mixins, palette, spacingDefaults, typography, overrides }) => ({
    menuButton: {
      marginRight: spacingDefaults.double,
    },
    title: {
      flexGrow: 1,
      margin: 0,
    },
    appBar: {
      backgroundColor: '#fff',
      color: 'black',
      width: `calc(100% - ${overrides.MuiDrawer.paper.width}px)`,
      marginLeft: overrides.MuiDrawer.paper.width,
    },
    toolbar: {
      ...mixins.toolbar,
      flexDirection: 'row-reverse',
    },
  });
