import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Home.styles';
import TitleBar from '../TitleBar/TitleBar';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import { getSSOList } from '../../utility/api';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import homeFeatureImage from './home_feature.png';
import { getExpenditure } from '../../actions/expenditureAction';
import Paper from '@material-ui/core/Paper';
import jiscLogo from '../Drawer/logo.svg';
import feedbackIcon from './feedback.png';
import pdfDownloadBlue from './pdf_image_blue.png';

import homeBg from './bp-home-image.jpg';
import PinkIcon from './icon-graph-pink.svg';
import GreenIcon from './icon-graph-green.svg';
import BlueIcon from './icon-graph-blue.svg';

import ExpenditureBlock from './../ExpenditureBlock/ExpenditureBlock';

const ssoAppId = process.env.REACT_APP_AUTH_APPID;
const returnUrl = '/login';

class Home extends React.Component {

  constructor(){
    super();
    this.state = {
      ssoAppId: process.env.REACT_APP_AUTH_APPID,
      returnUrl: '/login',
      sso: '',
      ssoList: [],
      currentSSO: null
    };
  }

  componentWillMount(){
    this.props.getExpenditure();
    getSSOList().then(res => {
      this.setState({ssoList: res});
    });
  }

  render () {
    const { 
      classes,
    } = this.props;



    const mailLink = 'mailto:JiscCloudPortal@jisc.ac.uk';

    const { isLoggedIn, userName } = this.props.account;

    console.log(this.props);

    return (
      <div>
        {isLoggedIn ?
          <React.Fragment>
          <Grid container spacing={3} style={{marginBottom: '20px'}}>
            <Grid item md={9} xs={12}>
              <Paper elevation={0} className={`${classes.paper} ${classes.welcomeBanner}`}>
                <Typography component="h1" variant="h5" className={classes.welcomeBannerText}>Welcome {userName}</Typography>
                <Typography className={classes.welcomeText}>
                  Thank you for logging into the online portal.
                </Typography>
                <Typography className={classes.welcomeText}>
                If you are unable to access your  organisation’s data please contact our <Link href='https://jcsd.service-now.com'>service desk</Link>.
                </Typography>

              </Paper>
            </Grid>
            <Grid item md={3} display={{xs: 'none'}}>
              <img src={homeFeatureImage} className={classes.homeFeatureImage} alt="Multiple tablet computers" />
            </Grid>
          </Grid>
          {this.props.expenditure.previous_month && <Grid container spacing={10} style={{marginBottom: '20px'}}>
            {console.log(this.props.expenditure)}
            <ExpenditureBlock expenditure={this.props.expenditure.current_month} color='#E1094A' title='CURRENT MONTHLY EXPENDITURE' icon={PinkIcon} />
            <ExpenditureBlock expenditure={this.props.expenditure.previous_month}  color='#707623' title='PREVIOUS MONTHLY EXPENDITURE' icon={GreenIcon} />
            {this.props.account.role === 'CustomerAdmin' && <ExpenditureBlock expenditure={this.props.expenditure.credits} color='#008eab' title='ACCOUNT BALANCE' icon={BlueIcon} />}
          </Grid> }


          <Grid container spacing={3} style={{marginTop: '20px', marginBottom: '20px'}}>
            <Grid item sm={12} md={4}>
              <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={classes.paper}>
                <Typography variant="h3" className={`${classes.featureTitle} ${classes.featureTitleJisc}`}>Our Services</Typography>
                <Typography className={classes.featureInfo}>As your trusted partner and reseller for Amazon Web Services, Azure and Microsoft Office 365, we can ensure you get the best value on cloud solutions.</Typography>

                <div className={classes.featureDownloadContainer}>
                  <Button variant="contained" color="primary" className={classes.featureDownloadButton} href="https://www.jisc.ac.uk/cloud"> 
                    View
                  </Button>

                </div>
              </Paper>
            </Grid>

            <Grid item sm={12} md={4}>
            <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={classes.paper}>
              <Typography variant="h3" className={classes.featureTitle}>FAQ</Typography>
              <Typography className={classes.featureInfo}>Visit our FAQ page to learn about the service, and to answer any of your questions.</Typography>

              <div className={classes.featureDownloadContainer}>
                <Button variant="contained" color="primary" className={classes.featureDownloadButton} href="/faq">
                  View
                </Button>
              
                <img src={pdfDownloadBlue} className={classes.pdfDownloadImage } alt="View Tutorial Icon" />
              </div>
            </Paper>
          </Grid>


            <Grid item sm={12} md={4}>
              <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={`${classes.paper} ${classes.paperFeedback}`}>
                <Typography variant="h3" className={`${classes.featureTitle} ${classes.featureTitleDarkGreen}`}>Feedback</Typography>
                <Typography className={classes.featureInfo}>We would love to hear your feedback on our online portal and any suggestions you have for future features which you may think will improve your experience.</Typography>

                <div className={classes.featureDownloadContainer}>
                  <Button href={mailLink} variant="outlined" color="primary" className={classes.featureDownloadButton}>
                    Give Feedback
                  </Button>
                
                  <img src={feedbackIcon} style={{padding: '30px'}} className={classes.pdfDownloadImage} alt="Feedback Icon" />
                </div>
              </Paper>
            </Grid>
          </Grid>
          </React.Fragment>
        : <React.Fragment>
        <Grid container spacing={3} style={{marginBottom: '20px'}}>
          <Grid item md={9} xs={12}>
            <Paper elevation={0} className={`${classes.paper} ${classes.welcomeBanner}`}>
              <Typography variant="h4" className={classes.welcomeBannerText}>Welcome {userName}</Typography>
              <Typography className={classes.welcomeText}>
                Welcome to Jisc’s Cloud Portal. Please log in to access further information. 
              </Typography>
              <Autocomplete
              className={classes.autocomplete}
              id="combo-box"
              fullWidth={true}
              options={this.state.ssoList}
              getOptionLabel={(option) => option.OrganisationName}
              renderInput={(params) => <TextField {...params} fullWidth={true} label="Institution Login" variant="outlined" />}
              onChange={(event, newValue) => {
                this.setState({
                  currentSSO: newValue
                })
              }}
            />
            <br />
            <Button variant="contained" color="primary" href={this.state.currentSSO ? `https://jwtsp.jisc.ac.uk/Shibboleth.sso/Login?entityID=${this.state.currentSSO.EntityId}&target=https://jwtsp.jisc.ac.uk/secure/auth-webv2.php?returl=${window.location.origin}${returnUrl}%26appid=${ssoAppId}` : '#'} disabled={!this.state.currentSSO}>Login</Button>

              <Typography className={classes.welcomeText}>
              If you do not have a user name and a password please contact <a href='mailto:JiscCloudPortal@jisc.ac.uk'>JiscCloudPortal@jisc.ac.uk</a>
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={3} display={{xs: 'none'}}>
            <img src={homeFeatureImage} className={classes.homeFeatureImage} alt="Multiple tablet computers" />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{marginTop: '20px', marginBottom: '20px'}}>
          <Grid item sm={12} md={4}>
            <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={classes.paper}>
              <Typography variant="h3" className={`${classes.featureTitle} ${classes.featureTitleJisc}`}>Our Services</Typography>
              <Typography className={classes.featureInfo}>As your trusted partner and reseller for Amazon Web Services, Azure and Microsoft Office 365, we can ensure you get the best value on cloud solutions.</Typography>

              <div className={classes.featureDownloadContainer}>
                <Button variant="contained" color="primary" className={classes.featureDownloadButton} href="https://www.jisc.ac.uk/cloud"> 
                  View
                </Button>

              </div>
            </Paper>
          </Grid>

          <Grid item sm={12} md={4}>
          <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={classes.paper}>
            <Typography variant="h3" className={classes.featureTitle}>FAQ</Typography>
            <Typography className={classes.featureInfo}>Visit our FAQ page to learn about the service, and to answer any of your questions.</Typography>

            <div className={classes.featureDownloadContainer}>
              <Button variant="contained" color="primary" className={classes.featureDownloadButton} href="/faq">
                View
              </Button>
            
              <img src={pdfDownloadBlue} className={classes.pdfDownloadImage } alt="View Tutorial Icon" />
            </div>
          </Paper>
        </Grid>


          <Grid item sm={12} md={4}>
            <Paper square elevation={3} style={{flexDirection:'column', height: '100%'}} className={`${classes.paper} ${classes.paperFeedback}`}>
              <Typography variant="h3" className={`${classes.featureTitle} ${classes.featureTitleDarkGreen}`}>Feedback</Typography>
              <Typography className={classes.featureInfo}>We would love to hear your feedback on our online portal and any suggestions you have for future features which you may think will improve your experience.</Typography>

              <div className={classes.featureDownloadContainer}>
                <Button href={mailLink} variant="outlined" color="primary" className={classes.featureDownloadButton}>
                  Give Feedback
                </Button>
              
                <img src={feedbackIcon} style={{padding: '30px'}} className={classes.pdfDownloadImage} alt="Feedback Icon" />
              </div>
            </Paper>
          </Grid>
        </Grid>
        </React.Fragment>}

        <Grid container style={{marginBottom: '30px'}}>
          <Grid item xs={12}>
          <Typography className={classes.welcomeText}>
            To find out about the portal's privacy notice and accessibility statement and information on the use of cookies, you can visit the <Link href='/policy'>policy page</Link>.
            </Typography>
            <br />
            <Typography className={classes.welcomeText}>
            Go to the  <Link href='/cookie'>Cookies' page</Link> to find out about the cookies used on this portal.
            </Typography>
          </Grid>
        </Grid>


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  expenditure: state.expenditure
});

const mapDispatchToProps = {
  getExpenditure
};

export default compose(
  withRouter,
  withStyles(styles),  
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Home);