import React from "react";
import { styles } from "./ExpenditureBlock.style";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography/Typography";

const ExpenditureBlock = (props) => {
  const { classes, color, expenditure, title, icon } = props;

  console.log(props);

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={4}>
        <Grid
          container
          className={classes.top}
          style={{ backgroundColor: color }}
        >
          <Grid item xs={12}>
            <Typography component="h2" variant="h2" className={classes.white}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item xs={8}>
            {expenditure.map(({ CurrencySymbol, val }) => (
              <Typography
                component="p"
                className={classes.containerText}
                style={{ color }}
              >
                {CurrencySymbol && (val || val == 0)
                  ? `${CurrencySymbol}${val}`
                  : "N/A"}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={4}>
            <img
              src={icon}
              alt={`${title} Graph icon`}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                position: "relative",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.bottom}
          style={{ backgroundColor: color }}
        >
          <Grid item xs={12}></Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default compose(withRouter, withStyles(styles))(ExpenditureBlock);
