import axios from 'axios';
import { httpStatus } from "../config/status";
import {mb} from "./mb";

const apiUrl = process.env.REACT_APP_API_URL;

const getConfig = (token) => {
  const contentType = {'Content-Type': 'application/json'};

  return token
    ? { headers: { ...contentType, 'Authorization': `${token}` } }
    : { headers: contentType };
};

const getConfigWithData = (token, data) => {
  const contentType = {'Content-Type': 'application/json'};

  return token
    ? { data , headers: { ...contentType, 'Authorization': `${token}` } }
    : { headers: contentType };
};

export const apiGet = async (url, token) => {
  try {
    const response = await axios.get(
      apiUrl + url,
      getConfig(token)
    );
    return {'status': mb(['status'])(response), 'data': mb(['data'])(response)};
  } catch (error) {
    throw error;
  }
};


export const apiGetBlob = async (url, token) => {
  try {
    const response = await axios.get(
      apiUrl + url,
      { 
        headers: { 'Authorization': `${token}` },
        responseType: 'blob'
      },
    );
    return {'status': mb(['status'])(response), 'data': mb(['data'])(response)};
  } catch (error) {
    throw error;
  }
};

export const apiPost = async (url, data, token) => {
  try {
    const response = await axios.post(
      apiUrl + url,
      data,
      getConfig(token),
    );
    return {'status': mb(['status'])(response), 'data': mb(['data'])(response)};
  } catch (error) {
    throw {'status': httpStatus.ERROR_CODE, 'data': mb(['data'])(error.response)};
  }
};

export const apiDelete = async (url, data = null, token) => {
  try {
    const response = await axios.delete(
      apiUrl + url,

      data
      ? getConfigWithData(token, data)
      : getConfig(token)
    );

    return {'status': mb(['status'])(response), 'data': mb(['data'])(response)};
  } catch (error) {
    throw {'status': httpStatus.ERROR_CODE, 'data': mb(['data'])(error.response)};
  }
};

export const getUserDetails = (EPPN, token) => {
  return apiGet(`GetUser?EPPN=${EPPN}`, token).then((response) => {
    return mb([1])(response);
  });
};

export const getSSOList = async () => {
  return apiGet(`sso`).then(response => {
    return response.data;
  });
}

export const getUserToken = async (token) => {
  return apiGet(`login?jwt=${token}&registrationId=${localStorage.getItem('registrationId')}`).then((response) => {
    return response.data;
  });
}

export const getRoles = async (token) => {
  return apiGet(`roles`, token).then((response) => {
    return response.data;
  });
}

export const getUsers = async (token) => {
  return apiGet(`users`, token).then((response) => {
    return response.data;
  });
}

export const getCustomers = async (token) => {
  return apiGet(`customers`, token).then((response) => {
    return response.data;
  });
}

export const postUser = async (data, token) => {
  return apiPost(`users`, data, token).then((response) => {
    return response;
  });
}

export const updateUser = async (data, token, id) => {
  return apiPost(`users/${id}`, data, token).then((response) => {
    return response;
  });
}

export const deleteUser = async (token, id) => {
  return apiDelete(`users/${id}`, null, token).then((response) => {
    return response;
  });
}

export const getCostCentres = async (token) => {
  return apiGet(`costcentres`, token).then((response) => {
    return response.data;
  });
}

export const postCostCentre = async (data, token) => {
  return apiPost(`costcentres`, data, token).then((response) => {
    return response;
  });
}

export const updateCostCentre = async (data, id, token) => {
  return apiPost(`costcentres/${id}`, data, token).then((response) => {
    return response;
  });
}

export const deleteCostCentre = async (id, token) => {
  console.log(id);
  return apiDelete(`costcentres/${id}`, null, token).then((response) => {
    return response;
  });
}

export const getCostCentreDetails = async (token, id) => {
  return apiGet(`costcentres/${id}`, token).then((response) => {
    return response.data;
  });
}

export const getSubscriptionById = async (token, id) => {
  return apiGet(`subscription/${id}`, token).then((response) => {
    return response.data;
  });
}

export const getSubscriptions = async (token) => {
  return apiGet(`subscriptions`, token).then((response) => {
    return response.data;
  });
}

export const updateSubscription = async (data, token) => {
  return apiPost(`apply/subscription`, data, token).then((response) => {
    return response;
  });
}

export const deleteSubscriptionRelation = async (data, token) => {
  return apiDelete(`apply/subscription`, data, token).then((response) => {
    return response;
  });
}

export const getThresholds = async (token) => {
  return apiGet(`thresholds`, token).then((response) => {
    return response.data;
  });
}

export const postThreshold = async (data, token) => {
  return apiPost(`thresholds`, data, token).then((response) => {
    return response;
  });
}

export const postThresholdUpdate = async (data, id, token) => {
  return apiPost(`thresholds/${id}`, data, token).then((response) => {
    return response;
  });
}

export const deleteThreshold = async (id, token) => {
  return apiDelete(`thresholds/${id}`, null, token).then((response) => {
    return response;
  });
}

export const applyUser = async (data, token) => {
  return apiPost(`apply/user`, data, token).then((response) => {
    return response;
  });
}

export const deapplyUser = async (data, token) => {
  return apiDelete(`apply/user`, data, token).then((response) => {
    return response;
  });
}

export const getCurrencies = async (token) => {
  return apiGet(`currencies`, token).then((response) => {
    return response.data;
  });
}

export const getUsersForCostCentre = async (token, id) => {
  return apiGet(`costcentres/${id}/users`, token).then((response) => {
    return response.data;
  });
}

export const getReportChartCredits = async (token, costCentreId) => {
  return apiGet(`graph/costcentregbp/${costCentreId}/daily`, token).then((response) => {
    return response.data;
  });
}

export const getReportChartSubscription = async (token, costCentreId, type) => {
  return apiGet(`graph/subscription/${costCentreId}/${type}`, token).then((response) => {
    return response.data;
  });
}

export const getDashboardChartSpend = async (token, costCentreId) => {
  return apiGet(`graph/dashone/${costCentreId}`, token).then((response) => {
    return response.data;
  });
}

export const getDashboardChartCloudCost = async (token, costCentreId) => {
  return apiGet(`graph/dashtwo/${costCentreId}`, token).then((response) => {
    return response.data;
  });
}

export const postUserPermission = async (data, token) => {
  return apiPost(`permissions`, data, token).then((response) => {
    return response;
  });
}

export const deleteUserPermission = async (data, token) => {
  return apiDelete(`permissions`, data, token).then((response) => {
    return response;
  });
}

export const getFileDownloadList = async (token) => {
  return apiGet(`files/list`, token).then((response) => {
    return response.data;
  });
}

export const getFileDownload = async (token, fileName) => {
  return apiGetBlob(`files/download/?filename=${fileName}`, token).then((response) => {
    return response.data;
  });
}

export const getCurrentMonthExpenditure = async (token) => {
  return apiGet(`monthly_expenditure/0`, token).then(response => {
    return response.data;
  });
};

export const getPreviousMonthExpenditure = async (token) => {
  return apiGet(`monthly_expenditure/1`, token).then(response => {
    return response.data;
  });
};

export const getRemainingCredits = async (token) => {
  return apiGet(`credits`, token).then(response => {
    return response.data;
  });
};

export const getCreditsGraph = async (token, customerSK) => {
  return apiGet(`graph/credits/${customerSK}`, token).then(response => {
    return response.data;
  });
}

export const getFAQ = async () => {
  return apiGet(`faq`).then(response => {
    return response.data;
  });
}

export const getEntities = async () => {
  return apiGet('sso').then(response => {
    return response.data
  });
}

export const sendInvite = async (userId, token) => {
  return apiPost(`invite/${userId}`, null, token).then(response => {
    return response.data;
  }) 
}