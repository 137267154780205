import React, { Fragment, useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Dashboard.styles';
import Typography from "@material-ui/core/Typography/Typography";
import {Box, Link} from '@material-ui/core';
import CloudCostPieChart from '../Charts/CloudCostPieChart/CloudCostPieChart';
import OverallSpendLineChart from '../Charts/OverallSpendLineChart/OverallSpendLineChart';
import Paper from '@material-ui/core/Paper';
import TitleBar from '../TitleBar/TitleBar';
import ThresholdCard from './ThresholdCard/ThresholdCard';
import SubscriptionLogo from "../CostCentre/SubscriptionLogo/SubscriptionLogo";

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getCustomers } from '../../actions/customerActions';
import { getCostCentres, getCostCentreDetailed } from '../../actions/costCentreActions';

import { getDashboardChartCloudCost } from "../../utility/api";

import { LoadingSpinner }  from "../LoadingSpinner";

const Dashboard = (props) => {

  const { classes, customers, account } = props;
  const [customer, setCustomer] = useState('');

  const [overallCosting, setOverallCosting] = useState('');
  const [loadingCosting, setLoadingCosting] = useState(true);
  const token = props.account.jwt;

  useEffect(()  => {
    if (!props.customers.isLoaded){
      props.getCustomers();
    }

    setCustomer(account.CustomerSK);
    loadData(account.CustomerSK);
  }, []);

  const handleChangeCustomer = event => {
    setCustomer(event.target.value);
    loadData(event.target.value);
  };

  const loadData = (customer) => {

    setLoadingCosting(true);

    getDashboardChartCloudCost(token, customer)
      .then(data => {
        setOverallCosting(data);
        setLoadingCosting(false);
      })
      .catch(
        error => {
          setOverallCosting('');
          setLoadingCosting(false);
        }
      );
  }

  const getCurrencySymbol = (currencyCode) => {
    switch(currencyCode) {
      case 'GBP':
        return '£';
      case 'USD':
        return '$';
      default:
        return currencyCode;
    }
  }

  let customerSelectMarkup = '';
  
  if (account.role === "JiscAdmin") {
    customerSelectMarkup = 
    <Grid container item xs={6} className={classes.alignRight}>

        <InputLabel htmlFor="Customer" className={classes.labelInline}>Customer</InputLabel>

        <Select value={customer} variant="outlined" label="Customer" id="Customer" onChange={handleChangeCustomer}>
        {customers.data.map(customer => (
            <MenuItem value={customer.CustomerSK} key={customer.CustomerSK}>
            {customer.CustomerName}
            </MenuItem>
        ))}
        </Select>

    </Grid>
  }

  return (
    <Fragment>

      <TitleBar pageName='Dashboard' />

      <Grid container spacing={3}>
        <Grid container item xs={6}>
          {/* <p>Credits</p> */}
        </Grid>

        {customerSelectMarkup}
      </Grid>

      <Grid container spacing={3}>

        <Grid item xs={12}>
          
          <Paper square elevation={3} className={classes.paper}>

          <Typography
            variant="h3"
          >
            Overall spending costs
          </Typography>

            <OverallSpendLineChart selectedCustomer={customer}/>
          </Paper>

          <Paper square elevation={3} className={`${classes.paper} ${classes.paperInner}`}>

            <Grid container spacing={0}>

              {loadingCosting &&
                <Grid item xs={12} className={classes.padded}>
                    <Typography
                      variant="h3"
                    >
                      Cloud Service Costing
                    </Typography>

                  <LoadingSpinner/>
                </Grid>
              }

              {!loadingCosting && !overallCosting &&
                <Grid item xs={12} className={classes.padded}>
                    <Typography
                      variant="h3"
                    >
                      Cloud Service Costing
                    </Typography>

                  <Typography variant="body1">No data to display</Typography>
                </Grid>
              }

              {!loadingCosting && overallCosting &&
                <Grid item xs={7} className={classes.padded}>

                  <div className={classes.costTableWrapper}>

                    <Typography
                      variant="h3"
                    >
                      Cloud Service Costing
                    </Typography>
                    
                    <div className={classes.costTableSurround}>
                    <table className={classes.costTable}>
                      <tbody>

                        {overallCosting && overallCosting.map(item => (
                          <tr key={item.DataSource}>
                            <td className={classes.cloudLogoWrapper}>
                              <SubscriptionLogo logoType={item.DataSource}/>
                            </td>
                            <td><Typography>{item.DataSource}</Typography></td>
                            <td><Typography>{getCurrencySymbol(item.CurrencyCode)}{item.Total}</Typography></td>
                          </tr>
                        ))}
                        
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Grid>
              }

              {!loadingCosting && overallCosting &&
                <Grid item xs={5}>
                  <Box borderLeft={1} borderColor="#D8D8D8" className={classes.padded}>  
                    {!loadingCosting && overallCosting &&
                      <CloudCostPieChart costData={overallCosting}/>
                    }
                  </Box>
                </Grid>
              }

              <Grid item xs={12} className={`${classes.padded} ${classes.additional}`}>
                <Link to='/cloud-services' variant='body2' component={RouterLink}>
                  View Cloud Services
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* <Grid item xs={4}>
          <Paper square elevation={3} className={`${classes.paper} ${classes.fullHeight}`}>
            <div className={classes.thresholdContainer}>
              <ThresholdCard 
                name='Threshold Name'
                costCentre='Cost centre'
                currentSpend={2000}
                thresholdLimit={4000}
              />

              <ThresholdCard 
                name='Threshold Name'
                costCentre='Cost centre'
                currentSpend={1500}
                thresholdLimit={3200}
              />
              
              <ThresholdCard 
                name='Threshold Name'
                costCentre='Cost centre'
                currentSpend={1300}
                thresholdLimit={3200}
              />
            </div>

            <Grid item xs={12} className={`${classes.padded} ${classes.additional} ${classes.bottomSticky}`}>
              <Link to='/cost-centre' variant='body2' component={RouterLink}>
                View Cost Centre
              </Link>
            </Grid> 
          </Paper>
        </Grid> */}

      </Grid>
    </Fragment>
  );
}

const mapDispatchToProps = {
  getCustomers,
  getCostCentres,
  getCostCentreDetailed,
};

const mapStateToProps = (state) => ({
  account: state.account,
  customers: state.customers,
  costCentres: state.costCentres,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
)(Dashboard);