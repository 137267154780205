import {
  CREATE_NOTIFICATION, 
  CLOSE_NOTIFICATION,
  OPEN_ADD_COST_CENTRE_MODAL,
  CLOSE_ADD_COST_CENTRE_MODAL,
  OPEN_EDIT_SUBSCRIPTION_MODAL,
  CLOSE_EDIT_SUBSCRIPTION_MODAL,
  OPEN_ADD_THRESHOLD_MODAL,
  CLOSE_ADD_THRESHOLD_MODAL,
  OPEN_DELETE_COST_CENTRE_CONFIRM_MODAL,
  CLOSE_DELETE_COST_CENTRE_CONFIRM_MODAL,
  OPEN_EDIT_COST_CENTRE_MODAL,
  CLOSE_EDIT_COST_CENTRE_MODAL,
  OPEN_EDIT_COST_CENTRE_USERS_MODAL,
  CLOSE_EDIT_COST_CENTRE_USERS_MODAL,
} from './uiActionTypes';

export const createNotification = (data) => ({
  type: CREATE_NOTIFICATION,
  payload: {
    data: data,
  },
});

export const closeNotification = () => ({
  type: CLOSE_NOTIFICATION,
});

export const openAddCostCentreModal = () => ({
  type: OPEN_ADD_COST_CENTRE_MODAL,
});

export const closeAddCostCentreModal = () => ({
  type: CLOSE_ADD_COST_CENTRE_MODAL,
});

export const openEditSubscriptionModal = () => ({
  type: OPEN_EDIT_SUBSCRIPTION_MODAL,
});

export const closeEditSubscriptionModal = () => ({
  type: CLOSE_EDIT_SUBSCRIPTION_MODAL,
});

export const openAddThresholdModal = (data) => ({
  type: OPEN_ADD_THRESHOLD_MODAL,
  payload: {
    data: data,
  },
});

export const closeAddThresholdModal = () => ({
  type: CLOSE_ADD_THRESHOLD_MODAL,
});

export const openDeleteCostCentreConfirmModal = () => ({
  type: OPEN_DELETE_COST_CENTRE_CONFIRM_MODAL,
});

export const closeDeleteCostCentreConfirmModal = () => ({
  type: CLOSE_DELETE_COST_CENTRE_CONFIRM_MODAL,
});

export const openEditCostCentreModal = () => ({
  type: OPEN_EDIT_COST_CENTRE_MODAL,
});

export const closeEditCostCentreModal = () => ({
  type: CLOSE_EDIT_COST_CENTRE_MODAL,
});

export const openEditCostCentreUsersModal = () => ({
  type: OPEN_EDIT_COST_CENTRE_USERS_MODAL,
});

export const closeEditCostCentreUsersModal = () => ({
  type: CLOSE_EDIT_COST_CENTRE_USERS_MODAL,
});
