import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { Route, Switch, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./Main.styles";
import Container from '@material-ui/core/Container';
import Home from '../Components/Home/Home';
import Login from '../Components/Login/Login';
import Register from '../Components/Register/Register';
import Dashboard from '../Components/Dashboard/Dashboard';
import Reports from '../Components/Reports/Reports';
import NewHomePage from '../Components/NewHomePage/NewHomePage';
import UserManagement from '../Components/UserManagement/UserManagement';
import CostCentre from '../Components/CostCentre/CostCentre';
import Downloads from '../Components/Downloads/Downloads';
import FAQ from '../Components/F&Q/f&q';
import PrivacyPage from '../Components/PrivacyPage/PrivacyPage';
import CookiePage from '../Components/CookiePage/CookiePage';
  

const Main = props => {
  const {classes} = props;
  const {isLoggedIn} = props.account;

  const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      isLoggedIn === true
        ? <Component {...props} />
        : <Redirect to='/' />
    )} />
  );

  return (
    <Container maxWidth={false} className={classes.container}>
      <div className={classes.spacer}></div>
      <Switch>
        <Route component={NewHomePage} exact path="/" />
        <Route component={Login} exact path="/login" />
        <Route component={Register} exact path="/register" />
        <Route component={FAQ} exact path='/faq' />
        <Route component={PrivacyPage} exact path='/policy' />
        <Route component={CookiePage} exact path='/cookie' />
        <AuthenticatedRoute component={Reports} exact path="/reports" />
        <AuthenticatedRoute component={UserManagement} exact path="/users" />
        <AuthenticatedRoute component={CostCentre} exact path="/cost-centre" />
        <AuthenticatedRoute component={Downloads} exact path="/downloads" />
      </Switch>
      {/* <Footer /> */}
    </Container>
  );
};

const mapStateToProps = (state) => ({
	account: state.account,
});

export default compose(
	withStyles(styles),
	connect(mapStateToProps)
)(Main);
