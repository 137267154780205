export const styles = ({ palette, spacingDefaults, typography }) => ({
  title: {
    color: palette.text.primary,
    fontWeight: typography.fontWeightBold,
    marginBottom: spacingDefaults.quad,
    marginTop: spacingDefaults.quad,
  },
  appLogo: {
    height: '40vmin',
  },
  costTable: {
    width: '100%',
    alignSelf: 'center',
  },
  cloudLogoWrapper: {
    textAlign: 'center',
  },
  cloudLogo: {
    maxHeight: '40px',
    marginTop: 20,
    marginBottom: 20,
  },
  paper: {
    padding: spacingDefaults.quad,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fullHeight: {
    height: '100%',
    padding: 0,
  },
  paperInner: {
    marginTop: 30,
    padding: 0,
  },
  padded: {
    padding: '15px',
  },
  additional: {
    borderTop: '1px solid',
    borderTopColor: '#D8D8D8',
  },
  thresholdContainer: {
    flexGrow: 1,
  },
  bottomSticky: {
    flexBasis: 0,
  },
  costTableWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  costTableSurround: {
    height: '100%',
    display: 'flex',
  },
  alignRight: {
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  labelInline: {
    alignSelf: 'center',
    marginRight: 15,
    marginBottom: 0,
  },
});