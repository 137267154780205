export const styles = ({ palette, spacingDefaults, typography }) => ({
  title: {
    color: palette.text.primary,
    fontWeight: typography.fontWeightBold,
    marginBottom: spacingDefaults.quad,
    marginTop: spacingDefaults.quad,
  },
  select: {
    marginTop: 20
  },
  alignRight: {
    flexDirection: 'column',
    alignContent: 'flex-end',
  },
});