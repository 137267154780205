import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { getFAQ } from './../../utility/api';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import TitleBar from './../TitleBar/TitleBar';

const formatFAQData = (data) => {
    const newObj = [];
    
    data.forEach(({
        Category,
        FaqOrder,
        CategoryOrder,
        Question,
        Answer
    }) => {
        if(!newObj[CategoryOrder - 1]){
            newObj[CategoryOrder - 1] = {
                Category,
                CategoryOrder,
                faq: []
            }
        }
    
        newObj[CategoryOrder - 1].faq[FaqOrder - 1] = {
            Question,
            Answer
        }
    });

    return newObj
}

const FAQ = () => {

    const [ faq, setFAQ ] = useState([]);

    useEffect(() => {
        getFAQ().then(data => {
            const faqData = formatFAQData(data);
            setFAQ(faqData);
        })
    }, []);

    return(
        <div>
            <TitleBar pageName='FAQs' />
            {
                faq.map(category => (
                    <div>
                        <Typography variant="h2" style={{margin: '20px 0'}}>{category.Category}</Typography>
                        {category.faq.map(({Question, Answer}) => (
                            <ExpansionPanel>
                                <ExpansionPanelSummary>
                                    <Typography variant="h3">{Question}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography><ReactMarkdown source={Answer} /></Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
                    </div>
                ))
            }
        </div>
        // <pre>
        //     {JSON.stringify(faq)}
        // </pre>
    )
};



const mapDispatchToProps = {};
  
const mapStateToProps = (state) => ({});
  
export default compose(
    withRouter,
    // withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(FAQ);