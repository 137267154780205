import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TitleBar from '../TitleBar/TitleBar';

const PrivacyPage = () => {

    return(
        <div>
            <TitleBar pageName='Privacy Policy' />
                <div>
                    <Typography variant="body1">The following information is needed for us to to give you access to the Jisc Cloud Billing portal and for you to continue to access it in the future. We’ll use it, as described in our standard privacy notice (at <Link href='https://www.jisc.ac.uk/website/privacy-notice'>https://www.jisc.ac.uk/website/privacy-notice</Link>), to provide the service you’ve requested, as well as to identify problems or ways to make the service better.</Typography>
                    <br />
                    <Typography variant="body1">The information we hold is your email address, first name and surname. This information is held securely in Jisc’s Azure subscription.</Typography>
                    <br />
                    <Typography variant="body1">We’ll keep the information while you have permission to use the portal or until your organisation no longer has a cloud managed service contract with Jisc or purchases a Cloud service through Jisc. We can also delete your information and remove your permission to access the portal if you email your request to <Link href='mailto:JiscCloudPortal@jisc.ac.uk'>JiscCloudPortal@jisc.ac.uk</Link></Typography>

                </div>
                <br />
                <div>
                    <Typography variant="h2">Accessibility statement for jisc.ac.uk</Typography>
                    <br />
                    <Typography variant="body1">This statement applies to content published on cloudportal.jisc.ac.uk.</Typography>
                    <br />
                    <Typography variant="body1">This portal is run by Jisc. It is designed to be used by as many people as possible. The text should be clear and simple to understand. You should be able to:</Typography>
                    <br />
                    <List>
                        <ListItem><Typography variant="body1">Change colours, contrast levels and fonts</Typography></ListItem>
                        <ListItem><Typography variant="body1">Zoom in up to 300% without problems</Typography></ListItem>
                        <ListItem><Typography variant="body1">Navigate most of the website using just a keyboard</Typography></ListItem>
                        <ListItem><Typography variant="body1">Navigate most of the website using speech recognition software</Typography></ListItem>
                        <ListItem><Typography variant="body1">Use most of the website using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver)</Typography></ListItem>
                    </List>
                    <br />
                    <Typography variant='body1'><Link href='https://mcmw.abilitynet.org.uk/'>AbilityNet</Link> has advice on making your device easier to use if you have a disability.</Typography>
                    <br />
                    <Typography variant="h2">How accessible is this website</Typography>
                    <br />
                    <Typography variant='body1'>We know some parts of this website aren’t fully accessible:</Typography>
                    <br />
                    <List>
                        <ListItem><Typography variant="body1">Some of the PDF documents aren’t fully accessible to screen reader software</Typography></ListItem>
                        <ListItem><Typography variant="body1">We have issues with text colour contrast</Typography></ListItem>
                        <ListItem><Typography variant="body1">We have some content issues</Typography></ListItem>
                    </List>
                    <br />
                    <Typography variant='h2'>How to request content in an accessible format</Typography>
                    <br />
                    <Typography variant='body1'>If you need information on this website in a different format like accessible PDF, large print, easy read, audio recording or braille contact us:</Typography>
                    <br />
                    <List>
                        <ListItem><Typography variant="body1">Email - help@jisc.ac.uk</Typography></ListItem>
                        <ListItem><Typography variant="body1">Call - 0300 3002212</Typography></ListItem>
                        <ListItem><Typography variant="body1">Or speak to your <Link href='https://www.jisc.ac.uk/contact/your-account-manager'>account manager</Link></Typography></ListItem>
                    </List>
                    <br />
                    <Typography variant='body1'>We’ll consider your request and get back to you.</Typography>
                    <br />
                    <Typography variant='h2'>Reporting accessibility problems with this website</Typography>
                    <br />
                    <Typography variant='body1'>If you find any problems that aren’t listed on this page or think we’re not meeting the requirements of the accessibility regulations, use the ‘Give Feedback” button on the Home page, or contact JiscCloudPortal@jisc.ac.uk.</Typography>
                    <br />
                    <Typography variant='h2'>Enforcement procedure</Typography>
                    <br />
                    <Typography variant='body1'>If you contact us with a complaint and you're not happy with our response <Link href='https://www.equalityadvisoryservice.com/'>contact the Equality Advisory and Support Service (EASS)</Link></Typography>
                    <br />
                    <Typography variant='body1'>The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’).</Typography>
                    <br />
                    <Typography variant='h2'>Technical information about this website’s accessibility</Typography>
                    <br />
                    <Typography variant='body1'>Jisc is committed to making its websites accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</Typography>
                    <br />
                    <Typography variant='body1'>This website is partially compliant with the <Link href='https://www.w3.org/TR/WCAG21/'>Web Content Accessibility Guidelines version 2.1</Link> AA standard, due to the non-compliances listed below.</Typography>
                    <br />
                    <Typography variant='h2'>Non-accessible content</Typography>
                    <br />
                    <Typography variant='body1'>Non-accessible content</Typography>
                    <br />
                    <Typography variant='body1'>The content listed below non-accessible for the following reasons.</Typography>
                    <br/>
                    <Typography variant='body1'>Some text and background combinations don’t have enough contrast - especially some table headings. They don’t meet WCAG 2.1 success criterion 1.4.3 (contrast - minimum). We are working on these.</Typography>
                    <br />
                    <Typography variant='body1'>Some icons are missing alternative text. They don’t meet WCAG 2.1 success criterion 1.1.1 Non-text Content (Level A). We are working on these.</Typography>
                    <br />
                    <Typography variant='body1'>Some links have incorrect text, They don’t meet WCAG 2.1 success criterion 2.4.4 Link Purpose (In Context) (Level A). We are working on these.</Typography>
                    <br />
                    <Typography variant='body1'>Heading order doesn’t clearly reflect the structure of the page in some sections. This does not meet WCAG success criterion 1.3.1 (info and relationships). We are addressing these on a rolling basis.</Typography>
                    <br />
                    <Typography variant='body1'>Issues with PDFs</Typography>
                    <br />
                    <Typography variant='body1'>Many of our older PDFs don’t meet accessibility standards - for example, they may not be marked up so they’re accessible to a screen reader. This doesn’t meet WCAG 2.1 success criterion 4.1.2 (name, role value). Any new PDFs or Word documents we publish should meet accessibility standards.</Typography>
                    <br />
                    <Typography variant='h2'>How we tested this website</Typography>
                    <br />
                    <Typography variant='body1'>We tested this website in August 2020 when we reviewed every page.</Typography>
                    <br />
                    <Typography variant='h2'>What we’re doing to improve accessibility</Typography>
                    <br />
                    <Typography variant='body1'>Whenever new features are released they must meet WCAG 2.1 AA. We’re also committed to working on the issues above.</Typography>
                    <br />
                    <Typography variant='body1'>This statement was prepared on 3 September 2020.</Typography>
                    <br />
                    <Typography variant='body1'>Content modified from the <Link herf='https://www.gov.uk/help/accessibility-statement'>GOV.UK accessbility statement</Link> - used through the <Link href='https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/'>Open Government Licence v3.0</Link>.</Typography>
                </div>
                <br />
                <br />
        </div>
    )
};



const mapDispatchToProps = {};
  
const mapStateToProps = (state) => ({});
  
export default compose(
    withRouter,
    // withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(PrivacyPage);

