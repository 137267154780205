import React, { useState, useEffect, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './UserManagement.styles';
import Typography from "@material-ui/core/Typography/Typography";
import { getUsers, getRoles, deleteUser, sendInvite } from "../../utility/api";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import NewUserForm from './NewUserForm/NewUserForm';
import EditUserForm from './EditUserForm/EditUserForm';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { LoadingSpinner }  from "../LoadingSpinner";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import TitleBar from '../TitleBar/TitleBar';

import { getCustomers } from '../../actions/customerActions';

const UserManagement = (props) => {
  const [userList, setUserList] = useState([]);
  const [roles, setRoles] = useState([]);

  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingRoles, setLoadingRoles] = useState(true);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);

  const [formResponseType, setFormResponseType] = useState(null);
  const [formResponse, setFormResponse] = useState(null);
  const [open, setOpen] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { account, classes, customers } = props;
  const token = props.account.jwt;

  const [tabIndex, setTabIndex] = React.useState(0);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('firstname');


  const StyledTabs = withStyles({
    indicator: {
      display: 'none',
    },
    root: {
      backgroundColor: '#57778E',
      marginTop: 55,
    },
  })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
  
  const StyledTab = withStyles(theme => ({
    root: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSizes.small,
      color: '#fff',
      borderRight: '1px solid',
      borderRightColor: '#fff',
      opacity: 1,
      backgroundColor: '#57778E',
      paddingTop: theme.spacingDefaults.triple,
      paddingBottom: theme.spacingDefaults.triple,
    },
    selected: {
      color: '#000',
      backgroundColor: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
    },
  }))(props => <Tab {...props} />);

  useEffect(() => {
    getUsers(token).then(data => {
      setUserList(data);
      setLoadingUsers(false);
    });

    getRoles(token).then(data => {
      if(account.role !== 'JiscAdmin'){
        data = data.filter(roles => roles !== 'JiscAdmin');
      }
      setRoles(data);
      setLoadingRoles(false);
    });
  }, [token]);

  useEffect(()  => {
    if (!props.customers.isLoaded){
      props.getCustomers();
    }
  }, []);

  const displayCreatedUser = (newUser) => {
    const CustomerName = customers.data.find(customer => customer.CustomerSK === newUser.CustomerSK).CustomerName;
    setUserList(userList => [...userList, {...newUser, CustomerName}]);
  }

  const displayUpdatedUser = (e) => {
    const CustomerName = customers.data.find(customer => customer.CustomerSK === e.CustomerSK).CustomerName;

    let updatedUserList = [...userList];
    updatedUserList[selectedUserIndex] = {...e, CustomerName};

    setUserList(updatedUserList);
}

  const removeUser = (userId) => {
    setUserList(userList.filter(user => user.id !== userId));
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleShowDeleteDialog = () => {
    setDialogOpen(true);
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  }

  const createNotification = (type, message) => {
    setFormResponseType(type);
    setFormResponse(message);
    setOpen(true);
  }

  const handleDeleteUser = () => {
    const userIdToDelete = selectedUserId;
    handleCloseMenu();

    deleteUser(token, userIdToDelete).then(response => {
      switch (response.status) {
        case 200:
          createNotification('success', 'User deleted successfully');
          removeUser(userIdToDelete);
          break;
        default:
          createNotification('error', response.data);
          break;
      }

      handleCloseDialog();
    });
  }

  const handleClick = (userId) => (e) => {
    const selectedUserIndex = userList.findIndex(user => user.id === userId);
    setSelectedUserIndex(selectedUserIndex);
    setSelectedUserId(userId);
    setAnchorEl(e.currentTarget);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleCloseMenu = () => {
    resetSelectedUser();
    setAnchorEl(null);
  };

  const resetSelectedUser = () => {
    setSelectedUserId(null);
    setSelectedUserIndex(null);
  }

  const handleShowEditDialog = () => {
    setEditDialogOpen(true);
  }

  const handleCloseEdit = () => {
    setEditDialogOpen(false);
  }

  const handleInviteOpenDialog = () => {
    setInviteDialogOpen(true);
  }

  const handleCloseInvite = () => {
    setInviteDialogOpen(false);
  }

  let editFormDisplayed = !loadingRoles && customers.isLoaded && selectedUserIndex != null;

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleChangeOrder = event => {
    setOrderBy(event.target.value);
  };

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  function desc(a, b, orderBy) {

    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }

  return (
    <React.Fragment>
      <TitleBar pageName='User Management' />

      <Grid item xs={12}>

        {!loadingRoles && customers.isLoaded && (
          <Fragment>
            <Grid container spacing={3}>

              <Grid container item xs={6}>
                <Box alignSelf="flex-end">
                  <Button variant="contained" color="primary" onClick={handleInviteOpenDialog}>
                    Invite User
                  </Button>
                </Box>
              </Grid>

              <Grid container item xs={6} className={classes.alignRight}>
                <FormControl>
                  <InputLabel id="sort">Sort By</InputLabel>
                  <Select
                    variant="outlined"
                    id="demo-simple-select-placeholder-label"
                    onChange={handleChangeOrder}
                    value={orderBy}
                    labelId="sort"
                    className={classes.select}
                  >
                    <MenuItem key="firstname" value="firstname">
                      First Name
                    </MenuItem>
                    <MenuItem key="lastname" value="lastname">
                      Last Name
                    </MenuItem>
                    <MenuItem key="email" value="email">
                      Email
                    </MenuItem>
                    <MenuItem key="CustomerName" value="CustomerName">
                      Customer Name
                    </MenuItem>
                    <MenuItem key="RoleName" value="RoleName">
                      Role
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <NewUserForm 
                createNotification={createNotification} 
                dialogOpen={inviteDialogOpen}
                closeDialog={handleCloseInvite}
                modalText={'Invite user'}
                customerList={customers.data}
                addUser={displayCreatedUser}
                userRoles={roles}
              />
            </Grid>
          </Fragment>
        )}

        {editFormDisplayed && 
          <EditUserForm
            createNotification={createNotification}
            dialogOpen={editDialogOpen}
            closeDialog={handleCloseEdit}
            showButton={false}
            closeParentMenu={handleCloseMenu}
            editingUser={userList[selectedUserIndex]}
            modalText={'Edit user'}
            customerList={customers.data}
            addUser={displayUpdatedUser}
            userRoles={roles}
          />
        }

        <StyledTabs value={tabIndex} onChange={handleChange} aria-label="content tab" square component={Paper}>
          <StyledTab label="Users" />
          
          {account.role === "JiscAdmin" &&
            <StyledTab label="Customers" />
          }

        </StyledTabs>

        <Typography className={classes.padding} />

        <Box mb={5} hidden={tabIndex !== 0}>
          {loadingUsers && (
            <LoadingSpinner />
          )}

          {!loadingUsers && customers.isLoaded && (
            <TableContainer component={Paper} square>
              <Table className={classes.table} aria-label="User table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">Name</TableCell>
                    <TableCell component="th" scope="row">Email</TableCell>
                    <TableCell component="th" scope="row">Customer Name</TableCell>
                    <TableCell component="th" scope="row">Role</TableCell>
                    <TableCell component="th" scope="row">Verified</TableCell>
                    <TableCell component="th" scope="row" align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={openMenu}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={handleShowEditDialog}>Edit</MenuItem>
                    <MenuItem onClick={handleShowDeleteDialog}>Delete</MenuItem>
                  </Menu>
                  
                  {stableSort(userList, getSorting(order, orderBy))
                  .map((row, index) => {
                    console.log(row);
                    return (
                      <TableRow key={row.email}>
                        <TableCell>{row.firstname} {row.lastname}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.CustomerName}</TableCell>
                        <TableCell>{row.RoleName}</TableCell>
                        <TableCell>
                          {row.isVerified ? 'Verified': <Button onClick={() => sendInvite(row.id, token)} color="primary">Send Invite</Button>}
                        </TableCell>
                        <TableCell align="right">
                          
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick(row.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>

                        </TableCell>
                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>

        <Box mb={5} hidden={tabIndex !== 1}>
          {customers.isLoading && (
            <LoadingSpinner />
          )}

          {customers.isLoaded && (
            <TableContainer component={Paper} square>
              <Table className={classes.table} aria-label="Customer table">
                <TableHead>
                  <TableRow>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Customer ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.data.map(row => (
                    <TableRow key={row.CustomerSK}>
                      <TableCell>{row.CustomerName}</TableCell>
                      <TableCell>{row.CustomerSK}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Grid>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} color={formResponseType} severity={formResponseType}>
          {formResponse}
        </Alert>
      </Snackbar>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  getCustomers,
};

const mapStateToProps = (state) => ({
  account: state.account,
  customers: state.customers,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserManagement);