import { LOGIN, LOGOUT, LOGIN_SUCCESS, LOGIN_FAILURE } from './authActionTypes';

export const login = (token) => ({
  type: LOGIN,
  payload: { token },
});

export const logout = () => ({
  type: LOGOUT,
});

export const getUserProfileSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  user
});

export const getUserProfileFailure = (message) => ({
  type: LOGIN_FAILURE,
  payload: {
    message,
  },
});