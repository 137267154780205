export const httpStatus = {
  'OK': "OK",
  'OK_CODE': 200,
  'CREATED_CODE': 204,
  'AUTHENTICATION_CODE': 401,
  'ERROR_CODE': 500,
  'API_ERROR': "API Error",
};

export const loginStatus = {
  'NO_USER': "NO_USER",
  'UNKNOWN_ERROR': "UNKNOWN_ERROR",
};
