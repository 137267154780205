import React from 'react';
import { compose } from "redux";

import { MuiThemeProvider, withStyles } from '@material-ui/core';
import theme from './../config/theme.styles';
import { styles } from './App.styles';

import Header from '../Components/Header/Header';
import DrawerComponent from '../Components/Drawer/Drawer';
import Main from './Main';

const App = ({ classes }) => {

  return (
    <div className={classes.app}>
      <MuiThemeProvider theme={theme}>
        <Header />
        <DrawerComponent />
        <Main />
      </MuiThemeProvider>
    </div>
  );
};

export default compose(withStyles(styles))(App);
