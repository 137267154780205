import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";

import { getCustomers } from '../../actions/customerActions';
import { getCostCentres, getCostCentreDetailed, getCurrencies } from '../../actions/costCentreActions';
import { 
  closeNotification,
  openAddCostCentreModal,
  closeAddCostCentreModal,
  openEditSubscriptionModal,
  openAddThresholdModal,
  closeAddThresholdModal,
  openEditCostCentreModal,
  closeEditCostCentreModal,
} from '../../actions/uiActions';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './CostCentre.styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TitleBar from '../TitleBar/TitleBar';
import { LoadingSpinner }  from "../LoadingSpinner";
import AddCostCentreForm from './AddCostCentre/AddCostCentre';
import EditCostCentreForm from './EditCostCentre/EditCostCentre';
import EditCostCentreUsersForm from './EditCostCentreUsers/EditCostCentreUsers';
import AddThresholdForm from './AddThreshold/AddThreshold';
import CostCentreTabContent from './CostCentreTabContent/CostCentreTabContent';
import ThresholdTabContent from './ThresholdTabContent/ThresholdTabContent';

const CostCentre = (props) => {

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const StyledTabs = withStyles({
    indicator: {
      display: 'none',
    },
    root: {
      backgroundColor: '#57778E',
      marginTop: 55,
    },
  })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
  
  const StyledTab = withStyles(theme => ({
    root: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSizes.small,
      color: '#fff',
      borderRight: '1px solid',
      borderRightColor: '#fff',
      opacity: 1,
      backgroundColor: '#57778E',
      paddingTop: theme.spacingDefaults.triple,
      paddingBottom: theme.spacingDefaults.triple,
    },
    selected: {
      color: '#000',
      backgroundColor: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
    },
  }))(props => <Tab {...props} />);

  const { 
    classes, 
    customers, 
    costCentres,
    ui,
  } = props;

  useEffect(()  => {
    if (!props.customers.isLoaded){
      props.getCustomers();
    }
  }, []);

  useEffect(()  => {
    if (!props.costCentres.isLoaded){
      props.getCostCentres();
    }
  }, []);

  useEffect(()  => {
    if (!props.costCentres.currenciesLoaded) {
      props.getCurrencies();
    }
  }, []);

  return (
    <div>
      <TitleBar pageName='Cost Centre' />

      <Snackbar open={ui.notificationActive} autoHideDuration={5000} onClose={props.closeNotification}>
        <Alert onClose={props.closeNotification} color={ui.notificationType} severity={ui.notificationType}>
          {ui.notificationMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>

        {customers.isLoaded && 
          <AddCostCentreForm
            closeDialog={closeAddCostCentreModal}
            modalText={'Add Cost Centre'}
          />
        }

        {ui.addThresholdModalOpen && 
          <AddThresholdForm
            closeDialog={closeAddThresholdModal}
          />
        }

        {ui.editCostCentreModalOpen && 
          <EditCostCentreForm
            closeDialog={closeEditCostCentreModal}
            modalText={'Edit Cost Centre'}
          />
        }

        {ui.editCostCentreUsersModalOpen && 
          <EditCostCentreUsersForm
            closeDialog={closeEditCostCentreModal}
            modalText={'Edit Cost Centre Users'}
          />
        }

         
          <Grid container spacing={3}>
            <Grid container item xs={6}>
              <Box mb={2}>
              {props.account.role != "Customer" &&
                <Button variant="contained" color="primary" onClick={props.openAddCostCentreModal} className={classes.costCentreButton}>
                  Add Cost Centre
                </Button>
              }
                <Button variant="contained" color="primary" onClick={() => props.openAddThresholdModal({title: 'Add Threshold'})}>
                  Add Threshold
                </Button>
              </Box>

            </Grid>
          </Grid>


        <StyledTabs value={tabIndex} onChange={handleTabChange} aria-label="content tab" square component={Paper}>
          <StyledTab label="Cost Centres" />
          <StyledTab label="Thresholds" />
        </StyledTabs>

        <Box mb={5} hidden={tabIndex !== 0} square component={Paper} className={classes.paddedBox}>
          {costCentres.isLoading && 
            <LoadingSpinner />
          }

          {!costCentres.isLoading && 
            <CostCentreTabContent />
          }
        </Box>

        <Box mb={5} hidden={tabIndex !== 1} square component={Paper} className={classes.paddedBox}>
          {costCentres.isLoading &&
            <LoadingSpinner />
          }

          {!costCentres.isLoading &&
            <ThresholdTabContent/>
          }
        </Box>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = {
  getCustomers,
  getCostCentres,
  getCurrencies,
  getCostCentreDetailed,
  closeNotification,
  openAddCostCentreModal,
  closeAddCostCentreModal,
  openEditSubscriptionModal,
  openAddThresholdModal,
  closeAddThresholdModal,
  openEditCostCentreModal,
  closeEditCostCentreModal,
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  costCentres: state.costCentres,
  account: state.account,
  ui: state.ui,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(CostCentre);
