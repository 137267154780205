import {
  GET_CUSTOMERS, 
  GET_CUSTOMERS_SUCCESS, 
  GET_CUSTOMERS_FAILURE,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
} from './customerActionTypes';
import { mb } from '../utility/mb';

export const getCustomers = () => ({
  type: GET_CUSTOMERS,
});

export const getCustomersSuccess = (data) => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: {
    data: data,
  },
});

export const getCustomersFailure = error => ({
  type: GET_CUSTOMERS_FAILURE,
  payload: {
    message: mb(['response', 'data'])(error),
  },
});

export const getUsers = () => ({
  type: GET_USERS,
});

export const getUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  payload: {
    data: data,
  },
});

export const getUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  payload: {
    message: mb(['response', 'data'])(error),
  },
});