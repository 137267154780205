import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getCustomersSuccess, getCustomersFailure, getUsersSuccess, getUsersFailure } from '../actions/customerActions';
import { GET_CUSTOMERS, GET_USERS } from '../actions/customerActionTypes';
import { getCustomers, getUsers } from "../utility/api";

export default function *customerSaga () {
  yield takeEvery(GET_CUSTOMERS, getCustomersAsync);
  yield takeEvery(GET_USERS, getUsersAsync);
}

function *getCustomersAsync (action) {
  try {
    const token = yield select(state => state.account.jwt);
    const data = yield call(getCustomers, token);
    
    yield put(getCustomersSuccess(data));
  } catch (error) {
    yield put(getCustomersFailure(error));
  }
}

function *getUsersAsync (action) {
  try {
    const token = yield select(state => state.account.jwt);
    const data = yield call(getUsers, token);
    
    yield put(getUsersSuccess(data));
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}
