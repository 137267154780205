export const styles = ({ palette, spacingDefaults, typography }) => ({
  alignRight: {
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  labelInline: {
    alignSelf: 'center',
    marginRight: 15,
    marginBottom: 0,
  },
  legendIconWrapper: {
    margin: 10,
    verticalAlign: 'middle',
  },
  legendIcon: {
    height: 15,
    width: 15,
  },
  legendLabel: {
    fontSize: 12,
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
});