import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
} from '../actions/authActionTypes';
import jwt_decode from 'jwt-decode';

export const account = (account = { isLoggedIn: false }, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      const { jwt } = action.user;
      const decodedToken = jwt_decode(jwt);
     
      return {
        ...account,
        isLoggedIn: true,
        role: decodedToken.RoleName,
        jwt,
        userName: decodedToken.firstname.concat(' ').concat(decodedToken.lastname),
        CustomerSK: decodedToken.CustomerSK,
        CustomerName: decodedToken.CustomerName,
        permissions: decodedToken.permissions
      };
    case LOGIN_FAILURE:
      return {
        authorised: false,
        reason: action.payload,
      };
    case LOGOUT:
      return { isLoggedIn: false };
    default:
      return account;
  }
};