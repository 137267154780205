
import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './TitleBar.styles';
import Typography from "@material-ui/core/Typography/Typography";
import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';

const TitleBar = (props) => {
  const currentDate = new Date();
  const { classes, pageName } = props;

  return (
    <Grid container spacing={3} className={classes.titleBar}>

      <Grid item xs={6}>
        <Typography variant="h1">
          {pageName}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography align='right' variant="body1">
          <Moment format='Do MMMM YYYY' date={currentDate} />
        </Typography>
      </Grid>

    </Grid>
  )
};

export default compose(
	withStyles(styles)
)(TitleBar);
